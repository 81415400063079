import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import CompanyList from "./companies-list";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { end_points } from "../../../../services/end_point/end_points";
import { dash1, dash2 } from "../../../../utils/imagepath";

const Dashboard = () => {
  type dashboard = {
    RegisteredCompanies: number | null;
    PricingPlan: number | null;
    ActiveStatus: {
      activeCount: number | null;
      inactiveCount: number | null;
    };
  };
  const [statusCount, setStatusCount] = useState<boolean>(false);

  const [dashBoardData, setDashBoardData] = useState<dashboard>({
    RegisteredCompanies: null,
    PricingPlan: null,
    ActiveStatus: {
      activeCount: null,
      inactiveCount: null,
    },
  });

  const { getData } = useContext(ApiServiceContext);

  const getDashBoardRegisteredCompanies = async () => {
    try {
      const result = await getData(end_points.registered_companies.url);
      return result;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getDashBoardPricingPlans = async () => {
    try {
      const result = await getData(end_points.pricing_plans.url);
      return result;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getDashBoardActiveSatus = async () => {
    try {
      const result = await getData(end_points.active_status.url);
      return result;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [
        registeredCompaniesResult,
        pricingPlansResult,
        activeStatusResult,
      ] = await Promise.all([
        getDashBoardRegisteredCompanies(),
        getDashBoardPricingPlans(),
        getDashBoardActiveSatus(),
      ]);

      setDashBoardData((prevData) => ({
        RegisteredCompanies:
          registeredCompaniesResult?.data?.count ??
          prevData.RegisteredCompanies,
        PricingPlan: pricingPlansResult?.data?.count ?? prevData.PricingPlan,
        ActiveStatus: {
          activeCount:
            activeStatusResult?.data?.activeCount ??
            prevData.ActiveStatus.activeCount,
          inactiveCount:
            activeStatusResult?.data?.inactiveCount ??
            prevData.ActiveStatus.inactiveCount,
        },
      }));
    };

    fetchData();
  }, [statusCount]);


  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Dashboard</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.dashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
              <div className="col-xl-6 col-lg-12 d-flex">
                <div className="dashboard-card flex-fill">
                  <ul className="nav">
                    <li>
                      <div className="dashboard-inner">
                        <div className="dashboard-inner-text">
                          <h6>Registered Companies</h6>
                          <h4>
                            {dashBoardData.RegisteredCompanies
                              ? dashBoardData.RegisteredCompanies
                              : 0}
                            +
                          </h4>
                        </div>
                        <div className="dashboard-inner-icon">
                          <span>
                            <img src={dash2} alt="" />
                          </span>
                        </div>
                      </div>
                      <p className="total-month">
                        {" "}
                        <Link to={routes.registeredCompanies.path}>
                          View All
                        </Link>
                      </p>
                    </li>
                    <li>
                      <div className="dashboard-inner">
                        <div className="dashboard-inner-text">
                          <h6>Pricing Plans</h6>
                          <h4>
                            {dashBoardData.PricingPlan
                              ? dashBoardData.PricingPlan
                              : 0}{" "}
                          </h4>
                        </div>
                        <div className="dashboard-inner-icon">
                          <span>
                            <img src={dash1} alt="" />
                          </span>
                        </div>
                      </div>
                      <p className="total-month">
                        {" "}
                        <Link to={routes.pricingPlan.path}>View All</Link>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex">
                <div className="row flex-fill">
                  <div className="col-xl-6 col-lg-12 d-flex w-100">
                    <div className="card w-100 flex-fill">
                      <div className="card-header">
                        <h5>Company Status</h5>
                      </div>
                      <div className="card-body">
                        <div className="status-div">
                          <ul>
                            <li>
                              <div className="active-set">
                                <h5>Active</h5>
                                <h6>
                                  <span className="badge badge-success rounded">
                                    {dashBoardData.ActiveStatus.activeCount
                                      ? dashBoardData.ActiveStatus.activeCount
                                      : 0}
                                    +
                                  </span>
                                </h6>
                              </div>
                            </li>
                            <li>
                              <div className="active-set ">
                                <h5 className="inactive">InActive</h5>
                                <h6>
                                  <span className="badge badge-danger rounded">
                                    {dashBoardData.ActiveStatus.inactiveCount
                                      ? dashBoardData.ActiveStatus.inactiveCount
                                      : 0}
                                    +
                                  </span>
                                </h6>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="dashboard-header">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h4>
                        Companies List (
                        {dashBoardData.RegisteredCompanies
                          ? dashBoardData.RegisteredCompanies
                          : 0}
                        )
                      </h4>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="dashboard-nav-list d-flex flex-wrap justify-content-end align-items-center">
                        <Link to="#" className="btn btn-add btn-hvr">
                          <i className="ti ti-circle-plus" /> Add Company
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
                <CompanyList setStatusCount={setStatusCount} />
              </div>
            </div>
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Dashboard;
