import React from "react";
import "./assets/icons/fontawesome/css/fontawesome.min.css";
import "./assets/icons/fontawesome/css/all.min.css";
import AppRouter from "./app.router";
import "./assets/icons/feather/css/iconfont.css";
import "./assets/icons/truck-icons/truck-icon.css";
import "./assets/icons/tabler-icons/tabler-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import { Provider } from "react-redux";
import store from "./core/redux/store";
import AxiosProvider from "./services/interceptor/interceptor";
import { ToastContainer } from "react-toastify";
import ApiServiceProvider from "./services/api/api.service";
import "react-toastify/dist/ReactToastify.css";
import SpinnerProvider from "./components/spinner/spinner";

function App() {
  return (
    <>
      <Provider store={store}>
        <SpinnerProvider>
          <AxiosProvider>
            <ApiServiceProvider>
              <AppRouter />
              <ToastContainer />
            </ApiServiceProvider>
          </AxiosProvider>
        </SpinnerProvider>
      </Provider>
    </>
  );
}

export default App;
