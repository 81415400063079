import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  truck06,
  truck02,
  signatureImg,
  noImg,
} from "../../../../utils/imagepath";
import { routes } from "../../../../utils/routes";
import { RepairDetail } from "../../../../components/json/repair-detail";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { end_points } from "../../../../services/end_point/end_points";
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from "../../../../utils/shared.module";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";
import {
  convertToDate,
  DESCRIPTION_LENGTH,
} from "../../../../utils/patterns/regex.pattern";
import { toast } from "react-toastify";
import { formatDate } from './../../../../utils/patterns/regex.pattern';

declare global {
  interface Window {
    bootstrap: any;
  }
}

const RepairDetails = () => {
  const [listData, setListData] = useState<any>([]);
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [repairTrackingData, setRepairTrackingData] = useState<any[]>([]);
  const [repairViewData, setRepairViewData] = useState<any>({});
  const navigate = useNavigate();
  const { getData, putData } = useContext(ApiServiceContext);
  const role = useSelector((state: any) => state.login.role);
  const viewData = useSelector((state: any) => state.common.repairViewData);
  const login_id = useSelector((state: any) => state.login.userDetails.id);
  const modelRef = useRef<any>(null);
  const closeRef = useRef<any>(null);
  const options = [
    { label: "Action Required", value: "0" },
    { label: "Working Inprogress", value: "1" },
    { label: "Work Completed", value: "2" },
  ];

  const validationSchema = yup.object().shape({
    completed_summary: yup.string()
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      completed_summary: repairViewData.completed_message,
    },
  });

  const [repairStatus, setRepairStatus] = useState<any>();
  const [availableOptions, setAvailableOptions] = useState([
    { label: "Service Initiated", value: 0 },
    { label: "Spare Parts Ordered", value: 1 },
    { label: "Spare Parts Received", value: 2 },
    { label: "Service Inprogress", value: 3 },
    { label: "Service Completed", value: 4 },
  ]);

  useEffect(() => {
    setAvailableOptions((options) =>
      options.map((option) => ({
        ...option,
        disabled: option.value <= repairStatus,
      }))
    );
  }, [repairStatus]);

  const handleOptionSelect = (value: any) => {
    if (value === 4) {
      const incompleteComponents = listData.some(
        (component: any) => component.status !== 2
      );
      if (incompleteComponents) {
        toast.warning("Kindly complete the inprogress components");
        return;
      }else{
        modelRef.current.click();
      }
    }
    if (value != 4) {
    setRepairStatus(value);
    updateRepairStatus(value);
    }
  };

  const handleCompletedSelect = (value: any) => {
    setRepairStatus(value);
    updateRepairStatus(value);
  };

  const updateRepairStatus = async (status: any) => {
    try {
      const payload = {
        repairid: viewData.repair_System.repair_Id,
        repair_status: status,
      };
      const urls = end_points.update_RepairStatus.url;
      const response = await putData(urls, payload);
      if (response.status === 200) {
        closeRef.current.click();
        getRepairTracking();
        getRepairById();

      }
    } catch (e) {
      console.log(e);
    }
  };


  const columns = [
    {
      header: "Components	",
      field: "component",
      key: "component",
      sortField: "",
      body: (data: any) => <div>{data.component}</div>,
    },
    {
      header: "Issue",
      field: "issue",
      key: "issue",
      sortField: "",
      body: (data: any) => <div className="text-muted">{data.issue}</div>,
    },
    {
      header: "Repair Status",
      field: "status",
      key: "status",
      sortField: "",
      body: (data: any) => {
        let statusText = "";

        switch (data.status) {
          case 0:
            statusText = "Action Required";
            break;
          case 1:
            statusText = "Work In Progress";
            break;
          case 2:
            statusText = "Work Completed";
            break;
          default:
            statusText = "Unknown Status";
        }

        return <span className="badge status-todo">{statusText}</span>;
      },
    },
    {
      header: "Action",
      field: "",
      key: "",
      sortable: false,
      body: (data: any) => {
        const [selectedValue, setSelectedValue] = useState(() => {
          const initialValue = options.find(
            (option) => option.value === String(data.status)
          );
          return initialValue ? initialValue.value : null;
        });

        const onValueChange = (e: any) => {
          setSelectedValue(e.value);
          componentStatusUpdate(data, e.value);
        };

        return (
          <div className="table-action">
            <Dropdown
              value={selectedValue}
              options={options}
              onChange={onValueChange}
              placeholder="Select"
              className="border-none"
              disabled={role !== 4 || repairViewData?.repair_status == 4}
            />
          </div>
        );
      },
    },
  ];

  const getRepairById = async () => {
    try {
      let url = end_points.get_RepairById.url;
      url += `?repair_id=${viewData.repair_System.repair_Id}`;
      const response = await getData(url);
      if (response.status === 200) {
        setRepairViewData(response?.data);
        setListData(response?.data?.repairComponents);
        setRepairStatus(response?.data?.repair_status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setValue("start_date", repairViewData.repair_startdate);
    setValue("end_date", repairViewData.repair_enddate);
    setValue("completed_summary", repairViewData.completed_message);
  }, [repairViewData]);

  const getRepairTracking = async () => {
    try {
      let url = end_points.get_RepairTracking.url;
      url += `?repairid=${viewData.repair_System.repair_Id}`;
      const response = await getData(url);
      if (response.status === 200) {
        setRepairTrackingData(response?.data?.data?.trip);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRepairTracking();
    getRepairById();
  }, []);

  const componentStatusUpdate = async (data: any, status: any) => {
    try {
      const statusNumber = Number(status);
      const repairComponentIdNumber = Number(data?.repair_component_id);

      const payload = {
        status: statusNumber,
        repair_component_id: repairComponentIdNumber,
      };

      const urls = end_points.update_RepairComponent.url;
      const response = await putData(urls, payload);
      if (response.status === 200) {
        getRepairTracking();
        getRepairById();
        closeRef.current.click()
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        repair_id: viewData.repair_System.repair_Id,
        completed_message: data.completed_summary,
      };

      const urls = end_points.update_RepairSummary.url;
      const response = await putData(urls, payload);
      if (response.status === 200) {
        toast.success(response?.data?.response?.responseMessage)
        getRepairTracking();
        getRepairById();
      }
    } catch (e) {
      console.log(e);
    }
  };


  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">
                  Repair Service - {viewData?.repair_System?.repair_refid}
                </h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.repairList.path} className="text-black">
                      Repair Service
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    {viewData?.repair_System?.repair_refid}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-lg-8 col-12">
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Overview</h4>
                    </li>
                  </ul>
                </div>
                {role == 4 && (
                  <div className="sub-header-dropdown d-flex align-items-center">
                    <span className="text-muted">Update Status</span>
                    <div
                      className="dropdown-toggle nav-link userset ms-3"
                      data-bs-toggle="dropdown"
                    >
                      {availableOptions.find(
                        (option) => option.value === repairStatus
                      )?.label || "Select Status"}
                    </div>
                    <div className="dropdown-menu dropdown-menu-end">
                      {availableOptions.map((option: any) => (
                        <button
                          key={option.value}
                          className={`dropdown-item ${
                            option.disabled ? "disabled" : ""
                          }`}
                          onClick={() =>
                            !option.disabled && handleOptionSelect(option.value)
                          }
                          disabled={option.disabled}
                        >
                          {option.label}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="card-body">
                  <div className="trip-head">
                    <div>
                      <h5>{repairViewData?.repair_name}</h5>
                      <p>{repairViewData?.repair_description}</p>
                    </div>
                    {repairViewData?.majorCount > 0 && (
                      <span className="badge status-danger">
                        {repairViewData?.majorCount} Major Issue
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-car" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Vehicle</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={repairViewData?.vehicle_image}
                                alt="Truck"
                              />
                            </Link>
                            <Link to="#">{repairViewData?.vehicle_number}</Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-steering-wheel" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Service Manager</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={
                                  repairViewData?.service_managerimage || noImg
                                }
                                alt="Truck"
                              />
                            </Link>
                            <Link to="#">
                              {repairViewData?.service_managername}
                            </Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                    
                        <div className="col-lg-6 col-12">
                          <div className="trip-details">
                            <div className="trip-details-img">
                              <i className="ti ti-calendar-up" />
                            </div>
                            <div className="trip-details-content">
                              <h6>Start Date</h6>
                              <h5>
                                {repairViewData?.repair_startdate != null
                                  ? formatDate(repairViewData?.repair_startdate)
                                  : "-"}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="trip-details">
                            <div className="trip-details-img">
                              <i className="ti ti-calendar-down" />
                            </div>
                            <div className="trip-details-content">
                              <h6>End Date</h6>
                              <h5>
                                {repairViewData?.repair_enddate != null
                                  ? formatDate(repairViewData?.repair_enddate)
                                  : "-"}
                              </h5>
                            </div>
                          </div>
                        </div>
                        {role !== 4 ? (
                      <>
                        <div className="col-12">
                          <div className="trip-status">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label>
                                  Service complete summary{" "}
                                  <span className="manitary">*</span>
                                </label>
                                <textarea className="form-control" disabled defaultValue={repairViewData.completed_message}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="col-12">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label>
                                  Service complete summary
                                </label>
                                <Controller
                                  name="completed_summary"
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      {...field}
                                      className={`form-control ${
                                        errors.completed_summary
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Please enter the chief complaint here*"
                                      onBlur={() =>
                                        trigger("completed_summary")
                                      }
                                      onChange={(event: any) => {
                                        field.onChange(event);
                                        trigger("completed_summary");
                                      }}
                                      maxLength={DESCRIPTION_LENGTH}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="mb-1">
                                    <button
                                      type="submit"
                                      className="btn btn-add w-100"
                                    >
                                      Update
                                    </button>
                                    <button
                                      ref={modelRef}
                                      data-bs-toggle="modal"
                                      data-bs-target="#terms-conditions"
                                      type="button"
                                      className="btn btn-add w-100 d-none"
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            <hr className="hr-custom" />
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                  {/* Sub Header */}
                  <div className="sub-header">
                    <div className="sub-header-content">
                      <ul className="nav">
                        <li>
                          <h4>Issues List</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Sub Header */}
                  {/* Table */}
                  <div className="table-responsive table-card prime-table">
                    <PrimeDataTable
                      setSearchQuery={setSearchQuery}
                      column={columns}
                      data={listData}
                      rows={rows}
                      setRows={setRows}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalRecords={totalRecords}
                    />
                  </div>
                  {/* /Table */}
                </div>
              </div>
              {/* /Table */}
            </div>
            <div className="col-lg-4 col-12">
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Repair Tracking</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="tracking-list">
                  <div className="card-body">
                    <div className="trip-tracking">
                      <ul>
                        {repairTrackingData?.map((item: any, index: any) => (
                          <li className="checked" key={index}>
                            <h5>{item?.label}</h5>
                            <h6>{item?.description}</h6>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Table */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-dialog-customized"
        id="terms-conditions"
        tabIndex={-1}
        aria-labelledby="termsConditions"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Acceptance Terms</h5>
              <Link
                to="#"
                className="clear-truck-lists"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="terms-box border p-3 mb-3">
                    <h5>Terms and Conditions</h5>
                    <p className="mt-2">
                      <b>General Site Usage</b>
                    </p>
                    <p>Last Revised: January 16, 2022</p>
                    <p>
                      This site is provided as a service to our visitors and may
                      be used for informational purposes only. Because the Terms
                      and Conditions contain legal obligations, please read them
                      carefully.
                    </p>
                    <p>
                      <b>1. YOUR AGREEMENT</b>
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce faucibus malesuada nulla, eget cursus nulla posuere
                      ut. Donec sed lorem nibh. Proin gravida libero a suscipit
                      volutpat.
                    </p>
                    <p>
                      <b>2. YOUR AGREEMENT</b>
                    </p>
                    <p>
                      Vivamus laoreet justo sed metus lobortis, vel blandit
                      metus facilisis. Sed vel lacus nunc. Nulla non urna vel
                      sapien tempor sodales. In aliquet lectus vel nulla
                      suscipit, nec fringilla quam feugiat.
                    </p>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="agreementCheckbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="agreementCheckbox"
                    >
                      I have read, understood, and agree with the terms above.
                    </label>
                  </div>
                  {isChecked && (
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <div className="upload-info">
                          <div className="upload-signature">
                            <img
                              src={repairViewData?.service_managersignature}
                              alt="Signature"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                ref={closeRef}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-add"
                disabled={!isChecked}
                onClick={() => handleCompletedSelect(4)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default RepairDetails;
