import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import AddTutorial from "./add-tutorial";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";

const Tutorial = () => {
  const { getData, putData, deleteData } = useContext(ApiServiceContext);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [showChild, setShowChild] = useState(false);

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.tutorial_paging.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.tutorials);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState<any>(null);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.tutorial_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleEditTutorial = (data: any) => {
    dispatch(setEditData(data));
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tutorialId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(tutorialId);
    } else {
      newSelectedRows.delete(tutorialId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const [visible, setVisible] = useState(false);

  const deletePlan = async (planId: number) => {
    const url = `${end_points.delete_tutorial.url}?id=${planId}`;

    if (planId) {
      try {
        const response = await deleteData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          setVisible(false);
          getList();
        } else {
          toast.error("Failed to delete tutorial");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An unexpected error occurred");
      }
    }
  };

  const accept = () => {
    if (selectedTutorial) {
      deletePlan(selectedTutorial.tutorial_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (plan: any) => {
    setSelectedTutorial(plan);
    setVisible(true);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.tutorial_id)}
              onChange={(e) => handleRowCheckboxChange(e, data.tutorial_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "tutorial_id",
      key: "tutorial_id",
      sortField: "tutorial_id",
    },
    {
      header: "Tutorial Video",
      field: "tutorial_video",
      key: "tutorial_video",
      sortField: "tutorial_video",
      body: (data: any) => {
        const [isOpen, setIsOpen] = useState(false);

        const handleThumbnailClick = () => {
          setIsOpen(true);
        };

        return (
          <>
            <div
              onClick={handleThumbnailClick}
              style={{
                position: "relative",
                cursor: "pointer",
                width: "20%",
                height: "20%",
              }}
            >
              <img
                src={"https://via.placeholder.com/80x80?text=Thumbnail"} // Placeholder thumbnail
                alt="Thumbnail"
                style={{ width: "100%", height: "100%", borderRadius: "10%" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  fontSize: "14px",
                  borderRadius: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                ▶
              </div>
            </div>

            {isOpen && (
              <Lightbox
                open={isOpen}
                close={() => setIsOpen(false)}
                slides={[
                  {
                    type: "video",
                    source: data.tutorial_video,
                  },
                ]}
                render={{
                  slide: (props) => (
                    <video
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      controls
                      autoPlay
                    >
                      <source src={props.slide.source} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ),
                }}
              />
            )}
          </>
        );
      },
    },
    {
      header: "Tutorial Description",
      field: "description",
      key: "description",
      sortField: "description",
    },
    {
      header: "Uploaded Date",
      field: "created_at",
      key: "created_at",
      sortField: "created_at",
      body: (data: any) => <span>{formatDate(data.created_at)}</span>,
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1}
              onChange={(event) => handleToggle(event, data.tutorial_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link to="#" className="dropdown-item">
                  <i className="feather icon-eye" /> View
                </Link>
                <Link
                  onClick={() => {
                    handleEditTutorial(item);
                    handleOffcanvas();
                  }}
                  to="#"
                  className="dropdown-item"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const tutorial_id = row;
    try {
      const payload = {
        id: tutorial_id,
        isActive: isChecked,
      };
      let url = end_points.update_tutorialstatus.url;
      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.tutorial_id === tutorial_id
              ? { ...row, status: isChecked }
              : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Tutorial</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.dashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Tutorial</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
              <div className="col-xl-12">
                <div className="dashboard-header">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h4>Tutorial List ({count})</h4>
                    </div>
                    <div className="col-md-6">
                      <div className="dashboard-nav-list d-flex flex-wrap justify-content-end align-items-center">
                        <Link
                          onClick={handleOffcanvas}
                          to="#"
                          className="btn btn-add btn-hvr truck-item"
                        >
                          <i className="ti ti-circle-plus" /> Add Tutorial
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card table-card">
                  <div className="card-body">
                    <div className="table-responsive prime-table">
                      <PrimeDataTable
                        setSearchQuery={setSearchQuery}
                        column={columns}
                        data={listData}
                        rows={rows}
                        setRows={setRows}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalRecords={totalRecords}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <AddTutorial successcall={getList} />}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this tutorial?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default Tutorial;
