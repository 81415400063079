import React, { useState } from "react";
import SearchFromApi from "./search";

const PaginationHeader = ({setRows, handleSearch}) => {
  const handleSelectChange = (value:any) => {
    setRows(value)
  }
  
  return (
    <>
      <div className="row mx-0 align-items-center">
        <div className="col-md-6 ps-0">
          <div id="tablelength">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label>
                <select
                  name="DataTables_Table_0_length"
                  aria-controls="DataTables_Table_0"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  onChange={(e)=> handleSelectChange(e.target.value)} 
                >
                  <option value={10} >10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
                <p className="ms-2"> Entries per page</p>
               
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6 pe-0">
          <ul className="nav nav-table-filter">
            <li>Search:</li>
            <li>
             <SearchFromApi callback={handleSearch}  />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PaginationHeader;
