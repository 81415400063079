import React, { useState } from "react";
import { Link } from "react-router-dom";
import { truck02, truck06 } from "../../../utils/imagepath";
import { Calendar } from "primereact/calendar";

const RepairDetailsServiceManager = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Repair Service - RE002</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to="index.html">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="repair-list.html">Repair Service</Link>
                  </li>
                  <li className="breadcrumb-item">RE002</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-lg-8 col-12">
              {/* Sub Header */}
              <div className="sub-header d-flex align-items-center justify-content-between mb-3">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Overview</h4>
                    </li>
                  </ul>
                </div>
                <div className="sub-header-dropdown d-flex align-items-center">
                  <span className="text-muted">Update Status</span>
                  <Link
                    to="#"
                    className="dropdown-toggle nav-link userset ms-3"
                    data-bs-toggle="dropdown"
                  >
                    Service Initiated
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to="#" className="dropdown-item">
                      Service Initiated
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Service Inprogress
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Service Completed
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="card-body">
                  <div className="trip-head">
                    <div>
                      <h5>Repair Title2</h5>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard.
                      </p>
                    </div>
                    <span className="badge status-danger">2 Major Issue</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-car" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Vehicle</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={truck06}
                                alt="Truck"
                              />
                            </Link>
                            <Link to="#">ABC10364D</Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-steering-wheel" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Driver</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={truck02}
                                alt="Truck"
                              />
                            </Link>
                            <Link to="#">Dominic</Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3">
                        <label>
                          Start Date <span className="manitary">*</span>
                        </label>
                        <div className="input-append">
                          <Calendar className="datetimepicker"
                            value={startDate}
                            onChange={(e: any) => setStartDate(e.value)}
                            placeholder="DD/MM/YYYY"
                          />
                          <span className="datetimepicker-span">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3">
                        <label>
                          End Date<span className="manitary">*</span>
                        </label>
                        <div className="input-append">
                          <Calendar className="datetimepicker"
                            value={endDate}
                            onChange={(e: any) => setEndDate(e.value)}
                            placeholder="DD/MM/YYYY"
                          />
                          <span className="datetimepicker-span">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label>
                            Service complete summary{" "}
                            <span className="manitary">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            defaultValue={
                              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard."
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="mb-1">
                            <Link to="#" className="btn btn-add w-100">
                              Update
                            </Link>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/* Sub Header */}
                  <div className="sub-header">
                    <div className="sub-header-content">
                      <ul className="nav">
                        <li>
                          <h4>Issues List</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Sub Header */}
                  {/* Table */}
                  <div className="row align-items-center d-none">
                    <div className="col-md-6">
                      <div id="tablelength" />
                    </div>
                    <div className="col-md-6">
                      <ul className="nav nav-table-filter">
                        <li>Search:</li>
                        <li>
                          <div id="tablefilter" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="table-responsive table-card">
                    <table className="table datatable mb-0">
                      <thead>
                        <tr>
                          <th>Components</th>
                          <th>Issue</th>
                          <th>Repair Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Oil Leak</td>
                          <td className="text-muted">Medium Issue</td>
                          <td>
                            <span className="badge status-todo">
                              Action Required{" "}
                            </span>
                          </td>
                          <td className="table-action">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Action Required{" "}
                              <i className="ti ti-chevron-down" />
                            </Link>
                            <div className="dropdown-menu">
                              <ul>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Working Inprogress
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Action Required
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Work Completed
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Break Issue</td>
                          <td className="text-muted">High</td>
                          <td>
                            <span className="badge status-inprogress">
                              Work in Progress
                            </span>
                          </td>
                          <td className="table-action">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Working Inprogress
                              <i className="ti ti-chevron-down" />
                            </Link>
                            <div className="dropdown-menu">
                              <ul>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Working Inprogress
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Action Required
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Work Completed
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Break Issue</td>
                          <td className="text-muted">High</td>
                          <td>
                            <span className="badge status-completed">
                              Completed
                            </span>
                          </td>
                          <td className="table-action">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Working Completed
                              <i className="ti ti-chevron-down" />
                            </Link>
                            <div className="dropdown-menu">
                              <ul>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Working Inprogress
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Action Required
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#" className="dropdown-item">
                                    Work Completed
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="tab-footer d-none">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div id="tableinfo" />
                      </div>
                      <div className="col-md-6">
                        <div id="tablepage" />
                      </div>
                    </div>
                  </div>
                  {/* /Table */}
                </div>
              </div>
              {/* /Table */}
            </div>
            <div className="col-lg-4 col-12">
              {/* Sub Header */}
              <div className="sub-header mb-1">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Repair Tracking</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="card-body">
                  <div className="trip-tracking">
                    <ul>
                      <li className="checked">
                        <h5>Service Initiated </h5>
                        <h6>Created on 10-06-2024</h6>
                      </li>
                      <li>
                        <h5>Service Inprogress</h5>
                      </li>
                      <li>
                        <h5>Service Completed</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Table */}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default RepairDetailsServiceManager;
