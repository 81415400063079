import React, { createContext, useState, useCallback } from "react";
import { truckLoader } from "../../utils/imagepath";

interface SpinnerContextType {
  isLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

interface Props {
  children: React.ReactNode;
}

export const SpinnerContext = createContext<SpinnerContextType>({
  isLoading: false,
  showLoader: () => {},
  hideLoader: () => {},
});

const SpinnerProvider: React.FC<Props> = ({ children }) => {
  const [loaderCount, setLoaderCount] = useState(0);

  const showLoader = useCallback(() => {
    setLoaderCount((prevCount) => prevCount + 1);
  }, []);

  const hideLoader = useCallback(() => {
    setTimeout(() => {
      setLoaderCount((prevCount) => Math.max(prevCount - 1, 0));
    }, 500);
  }, []);

  return (
    <SpinnerContext.Provider
      value={{
        isLoading: loaderCount > 0,
        showLoader,
        hideLoader,
      }}
    >
      {children}
      {loaderCount > 0 && (
        <div className="loading loaderInitialHeight">
        <div className="truck-loader">
          <img src={truckLoader} alt="Gif" />
        </div>
      </div>
      )}
    </SpinnerContext.Provider>
  );
};

export default SpinnerProvider;
