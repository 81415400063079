import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../utils/routes";

const OtpSuccess = () => {
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];

  return (
    <div>
      <div className="forgot-page-wrapper">
        <div className="forgot-page-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="static-page">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
                      <div className="forgot-pwd-topper">
                        <div className="forgot-pwd text-center">
                          <div className="forgot-password-inner">
                            <div className="forgot-password-title otp-success text-center">
                              <i className="fa-solid fa-circle-check" />
                              <h5>Verified</h5>
                              <p>
                                Hello {isAdmin === 'admin' ?('Admin'):('Super Admin')}! You have successfully verified the
                                account
                              </p>
                              {isAdmin === 'admin' ? (
                                <Link
                                to={routes.adminLogin.path}
                                className="btn btn-hvr link-tag w-100 mt-2"
                              >
                                Go to Login
                              </Link>
                              ) : (
                                <Link
                                to={routes.login.path}
                                className="btn btn-hvr link-tag w-100 mt-2"
                              >
                                Go to Login
                              </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpSuccess;
