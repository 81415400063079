import React, { useContext } from "react";
import { loginLogo } from "../../utils/imagepath";
import { Link } from "react-router-dom";
import { routes } from "../../utils/routes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { end_points } from "../../services/end_point/end_points";
import { ApiServiceContext } from "../../utils/shared.module";
import {
  email,
  emailMaxLength,
  userNameRegex,
} from "../../utils/patterns/regex.pattern";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



const ForgotPassword = () => {

  const navigate = useNavigate();
  
  const forgotValidation = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .trim()
      .matches(email, "Please enter a valid email")
      .required("Email is required"),
  });

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotValidation),
    defaultValues: {
      email: "",
    },
  });

  const { postData } = useContext(ApiServiceContext);
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];

  const onSubmit = async (data: any) => {
    try {
      let urls;
        if (isAdmin === 'super-admin') {
          urls = end_points.super_admin_otp.url; //super-admin
        } else {
          urls = end_points.admin_otp.url; //admin
        }
      const payload = {
        email: data?.email
      };
      const response = await postData(urls, payload);
      if (response?.status === 200) {
        toast.success("OTP has been sent to registered email.");
        if (isAdmin === 'super-admin') {
          navigate(routes.otp.path, { state: { otp_email : data.email, url: end_points.otp_super_admin.url } });
        }else{
          navigate(routes.adminOtp.path, { state: { otp_email : data.email, url: end_points.otp_admin.url } });
        }
      }
    } catch (error) {
    }
  };

  return (
    <div>
      <div className="forgot-page-wrapper">
        <div className="forgot-page-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="static-page">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
                      <div className="forgot-pwd-topper">
                        <div className="forgot-pwd text-center">
                          <img src={loginLogo} alt="img" />
                          <div className="forgot-password-inner">
                            <div className="forgot-password-title text-center">
                              <h5>Forgot Password</h5>
                              <p>
                                Enter your email and we'll send you a link to
                                reset your password.
                              </p>
                            </div>
                            <div className="forgot-password-form mt-3">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3 text-start form-forgot">
                                  <label htmlFor="email" className="form-label">
                                    Email
                                  </label>
                                  <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                      // <input
                                      //   type="email"
                                      //   className={`form-control ${
                                      //     errors.email ? "is-invalid" : ""
                                      //   }`}
                                      //   id="email"
                                      //   placeholder="Enter Email Address"
                                      //   {...field}
                                      // />
                                      <input
                                        {...field}
                                        type="text"
                                        placeholder="Enter Email"
                                        className={`form-control ${
                                          errors.email ? "is-invalid" : ""
                                        }`}
                                        onBlur={() => trigger("email")}
                                        onChange={(event: any) => {
                                          field.onChange(event);
                                          trigger("email");
                                        }}
                                        maxLength={emailMaxLength}
                                      />
                                    )}
                                  />
                                  {errors.email && (
                                    <div className="invalid-feedback">
                                      {errors.email.message}
                                    </div>
                                  )}
                                </div>

                                <button
                                  type="submit"
                                  className="btn submit-btn btn-hvr w-100"
                                >
                                  Submit
                                </button>
                                {isAdmin === 'super-admin' ? (
                                  <Link to={routes.login.path}>
                                  <i className="fa-solid fa-chevron-left" />{" "}
                                  Back to Login
                                </Link>
                                ) : (
                                  <Link to={routes.adminLogin.path}>
                                  <i className="fa-solid fa-chevron-left" />{" "}
                                  Back to Login
                                </Link>
                                )}
                                
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
