import React from "react";
import MediaGallery from "./mediaGallery";

function SubItem({ subItem = {}, index = 0 }) {
  const subMenuItem: any = subItem;
  return (
    <>
      <div className="checklist-lists">
        <div className="checklist-lists-count">
          <span>{index + 1}</span>
        </div>
        <div className="checklist-lists-content">
          <h5>{subMenuItem?.ck_item_name}</h5>
          {/* <p>{subMenuItem?.ck_item_icon}</p> */}
        </div>
      </div>
      {subMenuItem?.subitem?.map((subItem: any) => {
        return (
          <>
            <div className="checklist-sub-content mb-3">
              <h5>{subItem?.ck_subitem_name}</h5>
              <p>{subItem?.ck_subitem_description}</p>
              <ul>
                <li>
                  <div className="checkradio">
                    <label
                      htmlFor="issues"
                      className={
                        subItem?.answer == 0 ? "no-issues" : ""
                      }
                    >
                      No Issues
                    </label>
                  </div>
                </li>
                <li>
                  <div className="checkradio minor">
                    <label
                      htmlFor="minor"
                      className={
                        subItem?.answer == 1 ? "minor" : ""
                      }
                    >
                      Minor
                    </label>
                  </div>
                </li>
                <li>
                  <div className="checkradio major">
                    <label
                      htmlFor="major"
                      className={
                        subItem?.answer == 2 ? "major" : ""
                      }
                    >
                      Major
                    </label>
                  </div>
                </li>
              </ul>
              {subItem?.comments ? (
                <div className="minor-gallery-div">
                  <p>{subItem?.comments}</p>
                </div>
              ) : null}
              <div className="gallery-list mt-3">
                <ul>
                  {subItem?.images && (
                    <MediaGallery subItem={subItem} type="image" />
                  )}

                  {subItem?.video && (
                    <MediaGallery subItem={subItem} type="video" />
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default SubItem;
