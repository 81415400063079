import { Link } from "react-router-dom";
import { emptyLogo, free } from "../../../../utils/imagepath";
import { routes } from "../../../../utils/routes";
import CompanyList from "./companies-list";
import { useState } from "react";

const RegisteredCompanies = () => {
  const [countData, setCountData] = useState<any>(0);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Registered Companies</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.dashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Registered Companies</li>
                </ul>
              </div>
            </div>
            <div className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-md-6 mt-4">
                  <h4>Companies List ({countData || 0})</h4>
                </div>
                <div className="col-md-6">
                  <div className="dashboard-nav-list d-flex flex-wrap justify-content-end align-items-center"></div>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <CompanyList setCountData={setCountData} />
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default RegisteredCompanies;
