import React from "react";
import { routes } from "./routes";
import Dashboard from "../pages/feature-module/super-admin/companies/dashboard";
import RegisteredCompanies from "../pages/feature-module/super-admin/companies/registered-companies";
import PricingPlan from "../pages/feature-module/super-admin/pricing-plan";
import Tutorial from "../pages/feature-module/super-admin/tutorial";
import Profile from "../pages/feature-module/super-admin/profile";
import DriversList from "../pages/feature-module/admin/drivers";
import VehiclesList from "../pages/feature-module/admin/vehicles-list";
import AdminDashboard from "../pages/feature-module/admin/dashboard";
import RepairDetails from "../pages/feature-module/admin/repair/repair-details";
import RepairList from "../pages/feature-module/admin/repair/repair-list";
import InspectionList from "../pages/feature-module/admin/inspection/inspection-list";
import InspectionPreview from "../pages/feature-module/admin/inspection/inspection-preview";
import TripBreakdownDetails from "../pages/feature-module/admin/trip-breakdown-details";
//import TripDetails from "../pages/feature-module/admin/trip-details";
import Trip from "../pages/feature-module/admin/trip";
import FleetManagerDashboard from "../pages/feature-module/fleet-manager/dashboard";
import FleetManagerDriversList from "../pages/feature-module/fleet-manager/drivers-list";
import ServiceManagerDashboard from "../pages/feature-module/service-manager/dashboard";
import FleetManager from "../pages/feature-module/admin/fleetmanager";
import ServiceMangerList from "../pages/feature-module/admin/service-manager-list";
import TripDetails from "../pages/feature-module/admin/trip/trip-details";
import VehicleDetails from "../pages/feature-module/admin/vehicles-details/vehicle-details";
import RepairDetailsServiceManager from "../pages/feature-module/service-manager/repair-details";

export const authRoutes = [
  // super-admin
  {
    id: 1,
    path: routes.dashboard.path,
    element: <Dashboard />,
  },
  {
    id: 2,
    path: routes.registeredCompanies.path,
    element: <RegisteredCompanies />,
  },
  {
    id: 3,
    path: routes.pricingPlan.path,
    element: <PricingPlan />,
  },
  {
    id: 4,
    path: routes.tutorial.path,
    element: <Tutorial />,
  },
  {
    id: 5,
    path: routes.profile.path,
    element: <Profile />,
  },
  {
    id: 99,
    path: routes.adminProfile.path,
    element: <Profile />,
  },
  // admin
  {
    id: 6,
    path: routes.fleetManager.path,
    element: <FleetManager />,
  },
  {
    id: 7,
    path: routes.driversList.path,
    element: <DriversList />,
  },
  {
    id: 8,
    path: routes.vehiclesDetails.path,
    element: <VehicleDetails />,
  },
  {
    id: 9,
    path: routes.vehiclesList.path,
    element: <VehiclesList />,
  },
  {
    id: 10,
    path: routes.adminDashboard.path,
    element: <AdminDashboard />,
  },
  {
    id: 10,
    path: routes.repairDetails.path,
    element: <RepairDetails />,
  },
  {
    id: 11,
    path: routes.repairList.path,
    element: <RepairList />,
  },
  {
    id: 12,
    path: routes.serviceManagerList.path,
    element: <ServiceMangerList />,
  },
  {
    id: 13,
    path: routes.inspectionList.path,
    element: <InspectionList />,
  },
  {
    id: 14,
    path: routes.inspectionPreview.path,
    element: <InspectionPreview />,
  },
  {
    id: 15,
    path: routes.tripBreakdownDetails.path,
    element: <TripBreakdownDetails />,
  },
  {
    id: 16,
    path: routes.tripDetails.path,
    element: <TripDetails />,
  },
  {
    id: 17,
    path: routes.trip.path,
    element: <Trip />,
  },
  // fleet manager
  {
    id: 18,
    path: routes.fleetManagerDashboard.path,
    element: <FleetManagerDashboard />,
  },
  {
    id: 19,
    path: routes.fleetManagerDriversList.path,
    element: <FleetManagerDriversList />,
  },
  // service manager
  {
    id: 20,
    path: routes.serviceManagerDashboard.path,
    element: <ServiceManagerDashboard />,
  },
  {
    id: 21,
    path: routes.tripDetails.path,
    element: <TripDetails />,
  },
  {
    id: 22,
    path: routes.repairDetailsServiceManager.path,
    element: <RepairDetailsServiceManager />,
  },
];
