import parsePhoneNumberFromString from "libphonenumber-js";

export const capitalizeFirstLetter = (str: string) => {
    return str
      ? str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
      : str;
  };  
  export const validatePhoneNumber = (value: string) => {
    if (!value.startsWith('+')) {
      value = `+${value}`;
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber ? phoneNumber.isValid() : false;
  };
