import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import AddEditTrip from "./add-edit-trip";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import TripList from "./trip-list";
import { useContext, useEffect, useState } from "react";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";

const Trip = () => {
  const { getData } = useContext(ApiServiceContext);
  const [listData, setListData] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const [count, setCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [rows, setRows] = useState<number>(10);
  

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_trip.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.users);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const [vehiclesList, setVehiclesList] = useState([]);
  const [availableVehiclesList, setAvailableVehiclesList] = useState([]);
  const [driversList, setDriversList] = useState([]);
  const [availableDriversList, setAvailableDriversList] = useState([]);

  const getAssignNewVehicles = async () => {
    try {
      const url = end_points.get_all_vehicle.url;

      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.vehicle;
        const extractedList = list.map((vehicle: any) => ({
          code: vehicle.vc_id,
          name: vehicle.vehicle_name,
          status: vehicle.vehicle_status,
        }));
        setAvailableVehiclesList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAvailableDrivers = async () => {
    try {
      let url = end_points.assign_drivers.url;
      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.user;

        const extractedList = list.map((driver: any) => ({
          code: driver.user_id,
          name: driver.first_name,
        }));
        setAvailableDriversList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVehicles = async () => {
    try {
      const url = end_points.get_trip_all_vehicle.url;

      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.vehicle;
        const extractedList = list.map((vehicle: any) => ({
          code: vehicle.vc_id,
          name: vehicle.vehicle_name,
          status: vehicle.vehicle_status,
        }));
        setVehiclesList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getDrivers = async () => {
    try {
      let url = end_points.get_trip_all_driver.url;
      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.user;

        const extractedList = list.map((driver: any) => ({
          code: driver.user_id,
          name: driver.first_name,
        }));
        setDriversList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVehicles();
    getDrivers();
    getAssignNewVehicles();
    getAvailableDrivers();
  }, []);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Trip Service</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Trip</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Sub Header */}
          <div className="sub-header">
            <div className="sub-header-content">
              <ul className="nav">
                <li>
                  <h4>Total Trips ({count || 0})</h4>
                </li>
              </ul>
            </div>
            <div className="sub-header-list">
              <ul className="nav">
                <li>
                  <Link
                    to="#"
                    className="btn btn-add btn-hvr"
                    onClick={handleOffcanvas}
                  >
                    <i className="ti ti-circle-plus" /> Create Trip
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* /Sub Header */}
          {/* Table */}
          <div className="card table-card">
            <div className="card-body">
              <TripList
                listData={listData}
                searchQuery={setSearchQuery}
                rows={rows}
                setRows={setRows}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                successcall={getList}
                vehiclesList={vehiclesList}
                driversList={driversList}
                availableVehiclesList={availableVehiclesList}
                availableDriversList={availableDriversList}
              />
            </div>
          </div>
          {/* /Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && (
        <AddEditTrip
          vehiclesList={vehiclesList}
          driversList={driversList}
          availableVehiclesList={availableVehiclesList}
          availableDriversList={availableDriversList}
          successcall={getList}
        />
      )}
    </>
  );
};

export default Trip;
