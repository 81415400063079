import React from "react";
import { nameMaxLength } from "../../utils/patterns/regex.pattern";
import { debounce } from "../../utils/debounce";
interface searchProps {
  callback: Function;
}
const SearchFromApi: React.FC<searchProps> = ({ callback }) => {
  const dataApi = debounce(callback, 700);
  const handleChange = (e: any) => {
    dataApi(e.target.value?.trim());
  };
  return (
    <>
      <div id="tablefilter">
        <div id="DataTables_Table_0_filter" className="dataTables_filter">
          <label>
            {" "}
            <input
              onChange={handleChange}
              maxLength={nameMaxLength}
              type="search"
              className="form-control form-control-sm"
              placeholder=""
              aria-controls="DataTables_Table_0"
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default SearchFromApi;
