import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch } from "react-redux";
import { setInspectionData } from "../../../../core/redux/commonSlice";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { end_points } from "../../../../services/end_point/end_points";
import { formatDate } from "../../../../utils/patterns/regex.pattern";

const InspectionList = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [selectedRows, setSelectedRows] = useState<any>(new Set());
  const { getData } = useContext(ApiServiceContext);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item: any) => item.user_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };
  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    serviceManagerId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(serviceManagerId);
    } else {
      newSelectedRows.delete(serviceManagerId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.trip_Id)}
              onChange={(e) => handleRowCheckboxChange(e, data.trip_Id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "Insp ID",
      field: "inspection_refid",
      key: "inspection_refid",
      sortField: "inspection_refid",
    },
    {
      header: "Vehicle Name",
      field: "vehicle_Name",
      key: "vehicle_Name",
      sortField: "vehicle_Name",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link
            to={routes.inspectionPreview.path}
            className="avatar avatar-sm me-2"
          >
            <img
              className="avatar-img rounded-circle"
              src={data?.vehicle?.vehicle_image}
              alt="Truck"
            />
          </Link>
          <Link to={routes.inspectionPreview.path}>
            {data?.vehicle?.vehicle_name}
          </Link>
        </h2>
      ),
    },
    {
      header: "Driver",
      field: "driver",
      key: "driver",
      sortField: "driver",
    },
    {
      header: "Inspection Date",
      field: "inspection_date",
      key: "inspection_date",
      sortField: "inspection_date",
      body: (data: any) => (
        <span className="text-muted">{formatDate(data.inspection_Date)}</span>
      ),
    },
    {
      header: "Comments",
      field: "comments",
      key: "comments",
      sortField: "comments",
      body: (data: any) => (
        <div className="trip-data-comments">
          <p>{data.comments}</p>
        </div>
      ),
    },
    {
      header: "Checklist",
      field: "checklist",
      key: "checklist",
      sortField: "checklist",
      body: (data: any) => {
        const inspectionCounts = Array.isArray(data.inspectionCounts)
          ? data.inspectionCounts
          : [];

        const checklistClasses: any = [];

        inspectionCounts.forEach((inspection: any) => {
          const { majorCount, minorCount, checklist_completed } = inspection;

          let checkListClass = "check-list-grey";

          if (checklist_completed) {
            if (majorCount > 0) {
              checkListClass = "check-list-danger";
            } else if (minorCount > 0) {
              checkListClass = "check-list-warning";
            } else {
              checkListClass = "check-list-active";
            }
          }

          checklistClasses.push(checkListClass);
        });

        const completedCount = inspectionCounts.filter(
          (inspection: any) => inspection.checklist_completed
        ).length;

        return (
          <div className="data-check-list">
            <ul className="nav">
              {checklistClasses.map((checkListClass: any, index: any) => (
                <li key={index}>
                  <span className={checkListClass} />
                </li>
              ))}
            </ul>
            <p>
              {completedCount}/<b>6</b>
              <Link
                to={routes.inspectionPreview.path}
                className="view-checklist"
                onClick={() => dispatch(setInspectionData(data))}
              >
                View Checklist
              </Link>
            </p>
          </div>
        );
      },
    },
    {
      header: "Inspection Status",
      field: "inspection_Status",
      key: "inspection_Status",
      sortField: "inspection_Status",
      body: (data: any) => (
        <span className={`badge ${
          data.inspection_Status === "To Do"
            ? "status-todo"
            : data.inspection_Status === "Completed"
            ? "status-completed"
            : data.inspection_Status === "In Progress"
            ? "status-inprogress"
            : ""
        }`}>{data.inspection_Status}</span>
      ),
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <span
          className={`badge ${
            data.status === "No Issues"
              ? "status-completed"
              : data.status === "Major"
              ? "status-danger"
              : data.status === "Minor"
              ? "status-inprogress"
              : data.status === null
              ? "status-none-badge"
              : ""
          }`}
        >
          {data.status === null ? "-" : data.status}
        </span>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to={routes.inspectionPreview.path}
                  className="dropdown-item"
                  onClick={() => dispatch(setInspectionData(item))}
                >
                  <i className="feather icon-eye" /> View
                </Link>
                {/* <Link to="#" className="dropdown-item">
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleDeleteClick(item)}
                  >
                    <i className="feather icon-trash-2" /> Delete
                  </Link> */}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_inspection_list.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.inspectionList);
        setTotalRecords(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Inspection Tracking</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Inspection Tracking</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Inspection Tracking */}
          <div className="inspection-info">
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>Inspection List ({totalRecords})</h4>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
                <div className="table-responsive prime-table">
                  <PrimeDataTable
                    setSearchQuery={setSearchQuery}
                    column={columns}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Inspection */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default InspectionList;
