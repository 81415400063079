import React, { useContext, useEffect, useState } from "react";
import { adminChart } from "../../../utils/imagepath";
import { Link } from "react-router-dom";
import TripList from "../admin/trip/trip-list";
import { ApiServiceContext } from "../../../utils/shared.module";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../core/redux/sidebarSlice";
import { end_points } from "../../../services/end_point/end_points";
import AddEditTrip from "../admin/trip/add-edit-trip";
import ReactApexChart from "react-apexcharts";

const FleetManagerDashboard = () => {

  const { getData } = useContext(ApiServiceContext);
  const [listData, setListData] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const [count, setCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [rows, setRows] = useState<number>(10);
  

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_trip.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.users);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const [vehiclesList, setVehiclesList] = useState([]);
  const [availableVehiclesList, setAvailableVehiclesList] = useState([]);
  const [driversList, setDriversList] = useState([]);
  const [availableDriversList, setAvailableDriversList] = useState([]);
  const [chartData, setChartData] = useState<any>([]);
  const getAssignNewVehicles = async () => {
    try {
      const url = end_points.get_all_vehicle.url;

      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.vehicle;
        const extractedList = list.map((vehicle: any) => ({
          code: vehicle.vc_id,
          name: vehicle.vehicle_name,
          status: vehicle.vehicle_status,
        }));
        setAvailableVehiclesList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAvailableDrivers = async () => {
    try {
      let url = end_points.assign_drivers.url;
      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.user;

        const extractedList = list.map((driver: any) => ({
          code: driver.user_id,
          name: driver.first_name,
        }));
        setAvailableDriversList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVehicles = async () => {
    try {
      const url = end_points.get_trip_all_vehicle.url;

      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.vehicle;
        const extractedList = list.map((vehicle: any) => ({
          code: vehicle.vc_id,
          name: vehicle.vehicle_name,
          status: vehicle.vehicle_status,
        }));
        setVehiclesList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getDrivers = async () => {
    try {
      let url = end_points.get_trip_all_driver.url;
      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.user;

        const extractedList = list.map((driver: any) => ({
          code: driver.user_id,
          name: driver.first_name,
        }));
        setDriversList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [dashboardData, setDashboardData] = useState<any>([]);

  const getDashboardData = async () => {
    try {
      let url = end_points.get_fleet_dashboard.url;

      const response = await getData(url);
      if (response.status === 200) {
        console.log("setDashboardData",response.data)
        setDashboardData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVehicles();
    getDrivers();
    getAssignNewVehicles();
    getAvailableDrivers();
    getDashboardData();
    getChartData();
  }, []);


  const getChartData = async () => {
    try {
      let url = end_points.get_admin_chart.url;

      const response = await getData(url);
      if (response.status === 200) {
        console.log("get_admin_chart", response?.data.data);
        setChartData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [chartOptions, setChartOptions] = useState<any>({
    series: [],
    chart: {
      type: "bar",
      height: "auto",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        columnHeight: "10%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#1570EF", "#71DE71", "#FF0000"],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      min: 0,
      max: 10,
      labels: {
        show: false,
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      padding: {
        bottom: 0,
      },
    },
    fill: {
      opacity: 1,
      colors: ["#DAEAFF", "#FFE4E4", "#D6FFD6"],
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + " inspections";
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: -10,
      markers: {
        fillColors: ["#DAEAFF", "#FFE4E4", "#D6FFD6"],
        strokeWidth: 1,
      },
    },
  });

  useEffect(() => {
    if (chartData.length > 0) {
      // Extracting data from API response
      const dates = chartData.map((item: any) => item.date);
      const todoData = chartData.map((item: any) => item.todo);
      const inProgressData = chartData.map((item: any) => item.inprogress);
      const completedData = chartData.map((item: any) => item.completed);

      // Update chart options with the fetched data
      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        series: [
          { name: "To do", data: todoData },
          { name: "In progress", data: inProgressData },
          { name: "Completed", data: completedData },
        ],
        xaxis: { ...prevOptions.xaxis, categories: dates },
      }));
    }
  }, [chartData]);

  

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Dashboard</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to="index.html">Home</Link>
                  </li>
                  <li className="breadcrumb-item">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>Drivers</h5>
                        </div>
                        <div className="sevice-div service-manger-dash">
                          <ul>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-primary-text">{dashboardData?.alldrivers}</h5>
                                <h6>All Drivers</h6>
                              </div>
                            </li>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-complete-text">{dashboardData?.driverassigned}</h5>
                                <h6>Assigned</h6>
                              </div>
                            </li>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-inactive-text">{dashboardData?.driverunassigned}</h5>
                                <h6>Unassigned</h6>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>Service Manager</h5>
                        </div>
                        <div className="sevice-div service-manger-dash">
                          <ul>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-complete-text">{dashboardData?.allservice}</h5>
                                <h6>All Service Managers</h6>
                              </div>
                            </li>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-primary-text">{dashboardData?.serviceassigned}</h5>
                                <h6>Assigned</h6>
                              </div>
                            </li>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-inactive-text">{dashboardData?.serviceunassigned}</h5>
                                <h6>Unassigned</h6>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>Vehicles</h5>
                        </div>
                        <div className="sevice-div service-manger-dash">
                          <ul>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-complete-text">{dashboardData?.assign}</h5>
                                <h6>Assigned</h6>
                              </div>
                            </li>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-primary-text">{dashboardData?.unassign}</h5>
                                <h6>Unassigned</h6>
                              </div>
                            </li>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-inactive-text">{dashboardData?.outofservicevehicle}</h5>
                                <h6>Out for Service</h6>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex">
                <div className="card dashboard-info-card flex-fill">
                  <div className="card-body">
                    <div className="card-header inspection-card-header">
                      <h5>Inspection Tracking</h5>
                      <ul className="nav">
                        <li>
                          <span className="todo-box" /> To do
                        </li>
                        <li>
                          <span className="completed-box" /> Completed
                        </li>
                        <li>
                          <span className="inprogress-box" /> In progress
                        </li>
                      </ul>
                    </div>
                    <div className="admin-chart-info">
                    <div id="chart">
                        <ReactApexChart
                          style={{ height: 200 }}
                          options={chartOptions}
                          series={chartOptions.series}
                          type="bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>Total Trips ({count || 0})</h4>
                  </li>
                </ul>
              </div>
              {/* <div className="sub-header-list">
                <ul className="nav">
                  <li>
                    <Link
                      to="#"
                      className="btn btn-add"
                      onClick={handleOffcanvas}
                    >
                      <i className="ti ti-circle-plus" /> Create Trip
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
              <TripList
                listData={listData}
                searchQuery={setSearchQuery}
                rows={rows}
                setRows={setRows}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                successcall={getList}
                vehiclesList={vehiclesList}
                driversList={driversList}
                availableVehiclesList={availableVehiclesList}
                availableDriversList={availableDriversList}
              />
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Create Trip */}
      {offcanvas && (
        <AddEditTrip
          vehiclesList={vehiclesList}
          driversList={driversList}
          availableVehiclesList={availableVehiclesList}
          availableDriversList={availableDriversList}
          successcall={getList}
        />
      )}
      {/* /Create Trip */}
    </>
  );
};

export default FleetManagerDashboard;
