import React from "react";
import { Link } from "react-router-dom";
import { truck06, truck02 } from "../../../utils/imagepath";
import { routes } from "../../../utils/routes";

const TripBreakdownDetails = () => {
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Trip ID - TR002</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.trip.path} className="text-black">
                      Trip
                    </Link>
                  </li>
                  <li className="breadcrumb-item">TR002</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-lg-8 col-12">
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Overview</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="card-body">
                  <div className="trip-head">
                    <div>
                      <h5>Trip Title1</h5>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard.
                      </p>
                    </div>
                    <span className="badge status-danger">2 Major Issue</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-car" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Vehicle</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={truck06}
                                alt="Truck"
                              />
                            </Link>
                            <Link to="#">ABC10364D</Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-steering-wheel" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Driver</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={truck02}
                                alt="Truck"
                              />
                            </Link>
                            <Link to="#">Dominic</Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-calendar-up" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Trip Start Date</h6>
                          <h5>05-06-2024 </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-calendar-down" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Trip End Date</h6>
                          <h5>05-06-2024 </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="trip-status">
                        <h6>Trip Inspection Status</h6>
                        <span className="badge status-lightgrey">
                          2 Major Issue
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Table */}
            </div>
            <div className="col-lg-4 col-12">
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Trip Tracking</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="card-body">
                  <div className="trip-tracking">
                    <ul>
                      <li className="checked">
                        <h5>Trip Created</h5>
                        <h6>Created on 03-06-2024</h6>
                      </li>
                      <li className="checked">
                        <h5>Driver Accepted Trip Request</h5>
                        <h6>Accepted on 03-06-2024</h6>
                      </li>
                      <li className="checked">
                        <h5>Checklist Issue</h5>
                        <h6>3 Major Issue Occured</h6>
                      </li>
                      <li className="checked">
                        <h5>Trip Start</h5>
                        <h6>Assigned on 04-06-2024</h6>
                      </li>
                      <li className="issues">
                        <h5>Vehicle Breakdown</h5>
                        <h6>Updated on 05-06-2024</h6>
                      </li>
                      <li>
                        <h5>Trip End</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="details-btn">
                <ul>
                  <li className="w-100">
                    <Link
                      to="#"
                      className="btn btn-add w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#new-vehicle"
                    >
                      Assign New Vehicle
                    </Link>
                  </li>
                </ul>
              </div>
              {/* /Table */}
            </div>
          </div>
          {/* Sub Header */}
          <div className="sub-header">
            <div className="sub-header-content">
              <ul className="nav">
                <li>
                  <h4>Vehicle Breakdown Reason</h4>
                </li>
              </ul>
            </div>
          </div>
          {/* /Sub Header */}
          {/* Table */}
          <div className="card table-card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>3.444198</td>
                      <td>14.403401</td>
                      <td className="text-break">
                        High beams, blinkers, markers all not working fine, and
                        when you switch on the lights or toggle from high to low
                        you can see the low beams flicker and then go out.
                        Occasionally, mostly never at this point, the passenger
                        low beam will stay on, and when it works, it will work
                        or not work for a while while toggling high/low or
                        turning on/off the lights.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <div
        className="modal fade modal-dialog-customized"
        id="new-vehicle"
        tabIndex={-1}
        aria-labelledby="newvehicle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Assign New Vehicle</h5>
              <Link
                to="#"
                className="clear-truck-lists"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Choose Vehicle <span className="manitary">*</span>
                    </label>
                    <select className="select">
                      <option>ABC10364D</option>
                      <option>ABC10364D</option>
                      <option>ABC10364D</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Choose Driver <span className="manitary">*</span>
                    </label>
                    <select className="select">
                      <option>Cameron Jacob</option>
                      <option>Jacob</option>
                      <option>Cameron</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-add">
                Add
              </button>
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-dialog-customized"
        id="add-service"
        tabIndex={-1}
        aria-labelledby="newvehicle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Service Summary</h5>
              <Link
                to="#"
                className="clear-truck-lists"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Service Task Title <span className="manitary">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Trip Description <span className="manitary">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        defaultValue={
                          "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="text-muted">
                        <i className="ti ti-info-circle me-2" />
                        Maximum 255 Characters
                      </p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Choose Service Manager <span className="manitary">*</span>
                    </label>
                    <select className="select">
                      <option>Dominic</option>
                      <option>Dominic</option>
                    </select>
                  </div>
                  <div className="col-12 mb-2 text-end">
                    <Link to="#" className="link-add">
                      <i className="ti ti-plus" /> Add
                    </Link>
                  </div>
                  <div className="component-div">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <label className="form-label">
                            Components <span className="manitary">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Title Name"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <label className="form-label">
                            Repair Status<span className="manitary">*</span>
                          </label>
                          <select className="select">
                            <option>Low</option>
                            <option>High</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12  text-end">
                        <Link to="#" className="link-remove">
                          <i className="ti ti-Remove" /> Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-add">
                Update
              </button>
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="popup-canvas popup-canvas-view offcanvas offcanvas-end"
        tabIndex={-1}
        id="create-trip"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* Popup Header Start */}
        <div className="offcanvas-header popup-canvas-header">
          <div className="offcanvas-header-edit">
            <h5 id="offcanvasRightLabel" className="mt-0">
              Create Trip
            </h5>
          </div>
          <Link
            to="#"
            className="clear-truck-list"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="fas fa-xmark" />
          </Link>
        </div>
        {/* Popup Header end */}
        {/* Popup Body Content Start */}
        <div className="offcanvas-body popup-offcanvas-body popup-driver-view checklist-pb">
          <h6 className="mb-1">New Trip</h6>
          <p className="text-muted">Please add details to create new trip</p>
          <div className="card">
            <div className="card-body">
              <div className="truck-information">
                <div className="truck-information-grid mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Trip Name<span className="manitary">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Trip Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>
                          Start Date <span className="manitary">*</span>
                        </label>
                        <div className="input-append">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                          />
                          <span>
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>
                          End Date<span className="manitary">*</span>
                        </label>
                        <div className="input-append">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="MM/DD/YYYY"
                          />
                          <span>
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>
                          Choose Vehicle <span className="manitary">*</span>
                        </label>
                        <select className="select">
                          <option>Select</option>
                          <option>Bus</option>
                          <option>Car</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>
                          Choose Driver <span className="manitary">*</span>
                        </label>
                        <select className="select">
                          <option>Select</option>
                          <option>Bus</option>
                          <option>Car</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Trip Description <span className="manitary">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          defaultValue={
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <p className="text-muted">
                          <i className="ti ti-info-circle me-2" />
                          Maximum 255 Characters
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer truck-footer canvas-btn">
          <Link to="#" className="btn btn-light">
            Cancel
          </Link>
          <Link to="#" className="btn btn-add">
            Create
          </Link>
        </div>
        {/* Popup Body Content end */}
      </div>
    </>
  );
};

export default TripBreakdownDetails;
