import { Paginator } from "primereact/paginator";
import React from "react";

interface CustomPaginatorProps {
  currentPage: number;
  totalPages: number;
  rows: number;
  totalRecords: number;
  onPageChange: (newPage: number) => void;
}

const CustomPaginator: React.FC<CustomPaginatorProps> = ({
  currentPage,
  totalPages,
  totalRecords,
  rows,
  onPageChange,
}) => {
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const pageStart =
    currentPage > 1 ? (currentPage - 1) * rows + 1 : currentPage;
  const pageEnd = Math.min(currentPage * rows, totalRecords);

  const handlePaginatorChange = (event: any) => {
    onPageChange(event.page + 1);
  };

  return (
    <>
      {totalPages ? (
        <>
          <div className="row mx-0 align-items-center">
            <div className="col-md-12 p-0">
              <div className="table-pagination react-custom">
                <div className="show-entries-custom">
                  Showing {pageStart} - {pageEnd} of {totalRecords} entries
                </div>

                <div className="card">
                  <Paginator
                    first={(currentPage - 1) * rows}
                    rows={rows}
                    totalRecords={totalRecords}
                    onPageChange={handlePaginatorChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CustomPaginator;