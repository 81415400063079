import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { end_points } from "../../../../services/end_point/end_points";
import { useSelector } from "react-redux";
import { ApiServiceContext, routes } from "../../../../utils/shared.module";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

const AssignNewVehicle = (props: any) => {
  const validationSchema = Yup.object().shape({
    selectedVehicle: Yup.object().required("Vehicle is required"),
    selectedDriver: Yup.object().required("Driver is required"),
  });

  const [faultVehicle, setFaultVehicle] = useState<any>(false);

  const data = useSelector((state: any) => state.common.selectedData);

  const login_id = useSelector((state: any) => state.login.userDetails.id);
  const path = window.location.pathname;
  const findPath = path.split("/")[1];

  const { getData, putData } = useContext(ApiServiceContext);
  const trip_data = data;

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    console.log("getValues", getValues("selectedVehicle"));
  }, [getValues]);


  const onSubmit = async (data: any) => {
    // Log selected vehicle and driver
    try {
      const formData = new FormData();
      formData.append("trip_id", trip_data.trip_id);
      formData.append("vc_id", data.selectedVehicle.code);
      formData.append("user_id", data.selectedDriver.code);
      formData.append("updated_by", login_id);

      const url = end_points.assign_new_vechicle.url;

      const response = await putData(url, formData);

      if (response?.status === 200) {
        props.closeModal();
        if(findPath === 'trip-details'){
          navigate(routes.trip.path)
        }else{
          props.successcall();
        }
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  return (
    <>
      <div
        className="modal modal-dialog-customized fade show"
        id="new-vehicle"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Assign New Vehicle</h5>
              <Link
                to="#"
                className="clear-truck-lists"
                onClick={props.closeModal}
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label">
                        Choose Vehicle <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="selectedVehicle"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            filter
                            {...field}
                            value={field.value}
                            onChange={(e) => {
                              setValue("selectedVehicle", e.value);
                              const findStatus = e.value;
                              if (findStatus.status === "Major") {
                                setFaultVehicle(true);
                              } else {
                                setFaultVehicle(false);
                              }
                            }}
                            onBlur={() => trigger("selectedVehicle")}
                            options={props.vehiclesList}
                            optionLabel="name"
                            placeholder="Select"
                            className="w-100"
                          />
                        )}
                      />
                      {errors.selectedVehicle && (
                        <small className="text-danger">
                          {errors.selectedVehicle.message}
                        </small>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Choose Driver <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="selectedDriver"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            filter
                            {...field}
                            value={field.value}
                            onChange={(e) =>
                              setValue("selectedDriver", e.value)
                            }
                            onBlur={() => trigger("selectedDriver")}
                            options={props.driversList}
                            optionLabel="name"
                            placeholder="Select"
                            className="w-100"
                          />
                        )}
                      />
                      {errors.selectedDriver && (
                        <small className="text-danger">
                          {errors.selectedDriver.message}
                        </small>
                      )}
                    </div>
                    {faultVehicle && (
                      <div className="badge status-danger failed-status">
                        <p>
                          Selected vehicle checklist failed. Please select
                          choose another vehicle to complete trip.
                        </p>
                        <Link to="#">
                          <i className="fas fa-xmark" />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-add"
                    disabled={faultVehicle}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={props.closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignNewVehicle;
