import { Dropdown } from "primereact/dropdown";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { truck01 } from "../../../../utils/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { nameMaxLength } from "../../../../utils/patterns/regex.pattern";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  service_task: Yup.string().required("Service task title is required").trim(),
  components: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Component title is required"),
      repairStatus: Yup.string().required("Repair status is required"),
    })
  ),
  description: Yup.string().required("Description is required").trim(),
  serviceManager: Yup.object({
    name: Yup.string().trim().required("ServiceManager is required"),
  })
    .nullable()
    .required("ServiceManager is required"),
});

const AddService = (props: any) => {
  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const [components, setComponents] = useState([
    { title: "", repairStatus: "" },
  ]);
  const inspectionData = useSelector(
    (state: any) => state.common.inspectionData
  );
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<any>(validationSchema),
    defaultValues: {
      service_task: "",
      components: components,
      description: "",
      serviceManager: { name: "", code: "" },
    },
  });

  const repairStatus = [
    { name: "Low", code: "Low" },
    { name: "Medium", code: "Medium" },
    { name: "High", code: "High" },
  ];

  const { postData } = useContext(ApiServiceContext);
  const user_id = useSelector((state: any) => state.login.userDetails.id);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "components",
  });
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        repair_name: data.service_task,
        complaint: data.description,
        user_id: data.serviceManager.code,
        inspection_id: inspectionData?.insp_Id,
        type_of_repair: 2,
        components: data.components,
        created_by: user_id,
      };

      const urls = end_points.add_service.url;
      const response = await postData(urls, payload);
      if (response.status === 200) {
        dispatch(setAddCanvasSidebar(!offcanvas));
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddComponent = () => {
    append({ title: "", repairStatus: "" });
  };

  const handleRemoveComponent = (index: any) => {
    remove(index);
  };

  const hasRepairRequired = (inspections: any) => {
    return inspections?.some((inspection: any) =>
      inspection?.items?.some((item: any) =>
        item?.subitem?.some((subItem: any) => subItem.answer == 2)
      )
    );
  };

  return (
    <div
      // className="popup-canvas popup-canvas-view offcanvas offcanvas-end"
      className={`popup-canvas popup-canvas-view offcanvas offcanvas-end ${
        offcanvas ? "show" : ""
      }`}
    >
      {/* Popup Header Start */}
      <div className="offcanvas-header popup-canvas-header">
        <div className="offcanvas-header-edit">
          <h5 id="offcanvasRightLabel" className="mt-0">
            Add Service
          </h5>
        </div>
        <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
          <i className="fas fa-xmark" />
        </Link>
      </div>
      {/* Popup Header end */}
      {/* Popup Body Content Start */}
      <div className="offcanvas-body popup-offcanvas-body popup-driver-view checklist-pb">
        <h6 className="mb-1">Vehicle Allocate</h6>
        <p className="text-muted">
          Please add details to move vehicle to service
        </p>
        <div className="card">
          <div className="card-body pb-0">
            <div className="vehicle-grid inspection-grid">
              <div className="vehicle-grid-img add-service-img">
                <img
                  src={inspectionData?.vehicle?.vehicle_image}
                  className="img-fluid"
                  alt="Truck"
                />
              </div>
              <div className="vehicle-grid-content">
                <div className="vehicle-content-top">
                  <div className="vehicle-id-info mb-3">
                    <ul className="nav vehicle-id-list">
                      <li>
                        <h4>{inspectionData?.vehicle?.vehicle_name}</h4>
                      </li>
                      {hasRepairRequired(inspectionData?.inspection) && (
                        <li>
                          <span className="vehicle-badge bg-require">
                            Repair Required
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="vehicle-make-list">
                    <ul className="nav">
                      <li>{inspectionData?.vehicle?.make}</li>
                      <li>{inspectionData?.vehicle?.vehical_typename}</li>
                      <li>{inspectionData?.vehicle?.vehical_number}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="serviceForm">
          <div className="card">
            <div className="card-body">
              <div className="truck-information">
                <div className="truck-information-grid mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Service Task Title <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="service_task"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              className="form-control"
                              maxLength={nameMaxLength}
                              placeholder="Enter Full Name"
                              onBlur={() => trigger("service_task")}
                              onKeyDown={(e) => {
                                if (
                                  !/[a-zA-Z\s]/.test(e.key) &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("service_task");
                              }}
                            />
                          )}
                        />
                        {errors.service_task && (
                          <small className="text-danger">
                            {errors.service_task.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-1">
                        <label>
                          Complaints Description{" "}
                          <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="description"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <textarea
                              className={`form-control  ${
                                errors?.description ? "error-input" : ""
                              }`}
                              value={value ? value : ""}
                              onChange={onChange}
                              autoComplete="false"
                              maxLength={255}
                              placeholder="Enter type description"
                              onBlur={() => trigger("description")}
                            />
                          )}
                        />
                        {errors.description && (
                          <small className="text-danger">
                            {errors.description.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <p className="text-muted">
                          <i className="ti ti-info-circle me-2" />
                          Maximum 255 Characters
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Choose Service Manager{" "}
                          <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="serviceManager"
                          control={control}
                          render={({ field }) => (
                            <>
                              <Dropdown
                                filter
                                {...field}
                                value={field.value}
                                onBlur={() => trigger("serviceManager")}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger("serviceManager");
                                }}
                                options={props.managerListData}
                                optionLabel="name"
                                placeholder="Select service manager"
                                className={`w-100 ${
                                  errors.serviceManager ? "is-invalid" : ""
                                }`}
                              />
                            </>
                          )}
                        />
                        {errors.serviceManager && (
                          <small className="text-danger">
                            {errors.serviceManager?.name?.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-2 text-end">
                      <Link
                        to="#"
                        className="link-add"
                        onClick={handleAddComponent}
                      >
                        <i className="ti ti-plus" /> Add
                      </Link>
                    </div>
                    {fields.map((item, index) => (
                      <div className="col-md-12">
                        <div className="component-div">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-2">
                                <label className="form-label">
                                  Components <span className="manitary">*</span>
                                </label>
                                <Controller
                                  name={`components.${index}.title`}
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="text"
                                      placeholder="Title Name"
                                      className="form-control"
                                      onBlur={() => trigger(`components.${index}.title`)}
                                    />
                                  )}
                                />
                                {errors.components?.[index]?.title && (
                                  <small className="text-danger">
                                    {errors.components[index].title.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-2">
                                <label className="form-label">
                                  Repair Status
                                  <span className="manitary">*</span>
                                </label>
                                <Controller
                                  name={`components.${index}.repairStatus`}
                                  control={control}
                                  render={({ field }) => (
                                    <Dropdown
                                      filter
                                      options={repairStatus}
                                      optionLabel="name"
                                      placeholder="Select Repair Status"
                                      className="w-100"
                                      value={
                                        repairStatus.find(
                                          (option) =>
                                            option.name === field.value
                                        ) || null
                                      }
                                      onBlur={() => trigger(`components.${index}.repairStatus`)}
                                      onChange={(e) => {
                                        // Extract the 'name' property and set it as the field's value
                                        field.onChange(
                                          e.value ? e.value.name : ""
                                        );
                                        trigger(
                                          `components.${index}.repairStatus`
                                        );
                                      }}
                                    />
                                  )}
                                />
                                {errors.components?.[index]?.repairStatus && (
                                  <small className="text-danger">
                                    {
                                      errors.components[index].repairStatus
                                        .message
                                    }
                                  </small>
                                )}
                              </div>
                            </div>
                            {index > 0 && (
                              <div className="col-12  text-end">
                                <Link
                                  to="#"
                                  className="link-remove"
                                  onClick={() => handleRemoveComponent(index)}
                                >
                                  <i className="ti ti-Remove" /> Remove
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="card-footer truck-footer canvas-btn">
        <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
          Cancel
        </Link>
        <button type="submit" className="btn btn-add" form="serviceForm">
          Update
        </button>
      </div>
      {/* Popup Body Content end */}
    </div>
  );
};
export default AddService;
