import React from "react";
import { noImg } from "../../../../utils/imagepath";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import { setExpandMenu } from "../../../../core/redux/sidebarSlice";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import { logout } from "../../../../core/redux/loginSlice";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  const location = useLocation();

  const pathname: any = window.location?.pathname;
  const findPath = pathname.split("/")[1];
  const { name, profile_url } = useSelector(
    (state: any) => state.login.userDetails
  );
  const handleLogout = (data: any) => {
    dispatch(logout(data));
  };
  const role = useSelector((state: any) => state.login.role);

  return (
    <>
      {" "}
      {/* Sidebar */}
      <div
        className="sidebar"
        id="sidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Scrollbars autoHide>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Main</h6>
                <ul>
                  <li className="submenu">
                    {role === 1 && (
                      <Link
                        to={routes.adminDashboard.path}
                        className={
                          pathname.includes("dashboard") ? "active" : ""
                        }
                      >
                        <i className="ti ti-layout-dashboard" />
                        <span>Dashboard</span>
                      </Link>
                    )}

                    {role === 3 && (
                      <Link
                        to={routes.fleetManagerDashboard.path}
                        className={
                          pathname.includes("dashboard") ? "active" : ""
                        }
                      >
                        <i className="ti ti-layout-dashboard" />
                        <span>Dashboard</span>
                      </Link>
                    )}

                    {role === 4 && (
                      <Link
                        to={routes.serviceManagerDashboard.path}
                        className={
                          pathname.includes("dashboard") ? "active" : ""
                        }
                      >
                        <i className="ti ti-layout-dashboard" />
                        <span>Dashboard</span>
                      </Link>
                    )}
                  </li>
                  <li className="submenu">
                    {role !== 4 && (
                      <Link
                        to={routes.trip.path}
                        className={pathname.includes("trip") ? "active" : ""}
                      >
                        <i className="ti ti-list-check" />
                        <span>Trip</span>
                      </Link>
                    )}
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Others</h6>
                <ul>
                  {role !== 4 && (
                    <>
                      <li className="submenu">
                        {role !== 3 && (
                          <Link
                            to={routes.fleetManager.path}
                            className={
                              pathname === "/fleet-manager" ? "active" : ""
                            }
                          >
                            <i className="ti ti-user-check" />
                            <span>Fleet Manager</span>
                          </Link>
                        )}
                      </li>
                      <li className="submenu">
                        <Link
                          to={routes.driversList.path}
                          className={
                            pathname.includes("drivers-list") ? "active" : ""
                          }
                        >
                          <i className="ti ti-steering-wheel" />
                          <span>Drivers</span>
                        </Link>
                      </li>
                      <li className="submenu">
                        <Link
                          to={routes.vehiclesList.path}
                          className={
                            pathname.includes("vehicles-list") ? "active" : ""
                          }
                        >
                          <i className="ti ti-car" />
                          <span>Vehicles</span>
                        </Link>
                      </li>
                      <li className="submenu">
                        <Link
                          to={routes.serviceManagerList.path}
                          className={
                            pathname.includes("service-manager-list")
                              ? "active"
                              : ""
                          }
                        >
                          <i className="ti ti-user-check" />
                          <span>Service Manger</span>
                        </Link>
                      </li>
                      <li className="submenu">
                        <Link
                          to={routes.inspectionList.path}
                          className={
                            pathname.includes("inspection") ? "active" : ""
                          }
                        >
                          <i className="ti ti-square-rounded-check" />
                          <span>Inspection Tracking</span>
                        </Link>
                      </li>
                      <li className="submenu">
                        <Link
                          to={routes.repairList.path}
                          className={
                            pathname.includes("repair-list") ||
                            pathname.includes("repair-details")
                              ? "active"
                              : ""
                          }
                        >
                          <i className="ti ti-tool" />
                          <span>Repair System Tracking</span>
                        </Link>
                      </li>
                    </>
                  )}
                  {role === 4 && (
                    <li className="submenu">
                      <Link
                        to={routes.repairList.path}
                        className={
                          pathname.includes("repair-list") ||
                          pathname.includes("repair-details")
                            ? "active"
                            : ""
                        }
                      >
                        <i className="ti ti-tool" />
                        <span>Repair System Tracking</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
            <div className="sidebar-footer">
              <div className="sidebar-profile">
                <Link to="login">
                  <div className="sidebar-profile-img">
                    <img
                      src={profile_url || noImg}
                      className="img-fluid"
                      alt="Profile"
                    />
                    <p>
                      <span>{name}</span>Admin
                    </p>
                  </div>
                </Link>
                <div className="sidebar-profile-logout">
                  <Link
                    to={"#"}
                    onClick={() => {
                      handleLogout(findPath);
                    }}
                  >
                    <i className="ti ti-logout-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* /Sidebar */}
    </>
  );
};

export default AdminSidebar;
