import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setExpandMenu,
  setMiniSidebar,
  setMobileSidebar,
} from "../../../../core/redux/sidebarSlice";
import { routes } from "../../../../utils/routes";
import { hello, logo, noImg } from "../../../../utils/imagepath";
import store from "../../../../core/redux/store";
import { logout } from "../../../../core/redux/loginSlice";
import { capitalizeFirstLetter } from './../../../../utils/constant';
interface UserDetails {
  name?: string;
  profile_url?: string;
}
const Header = () => {
  const miniSidebar = useSelector((state: any) => state.sidebar.miniSidebar);
  const mobileSidebar = useSelector(
    (state: any) => state.sidebar.mobileSidebar
  );
  const userDetails: UserDetails = store?.getState()?.login?.userDetails || {};
  const { name = "", profile_url } = userDetails;
  const profile_img = profile_url || noImg;
  const path = window.location?.pathname;
  const findPath = path.split("/")[1];

  const dispatch = useDispatch();
  const handleMiniSidebar = () => {
    dispatch(setMiniSidebar(!miniSidebar));
  };
  const handleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };
  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  const [headerClass, setHeaderClass] = useState("header");
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold) {
        setHeaderClass("header active");
      } else {
        setHeaderClass("header");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = (data: any) => {
    dispatch(logout(data));
  };

  return (
    <>
      {/* Header */}
      <div className={headerClass}>
        {/* Logo */}
        <div
          className={`header-left ${miniSidebar ? "active" : ""}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link to={routes.dashboard.path} className="logo logo-normal">
            <img src={logo} alt="" />
          </Link>
          <Link to={routes.dashboard.path} className="logo-small">
            <p>M</p>
          </Link>
          <Link id="toggle_btn" to="#" onClick={handleMiniSidebar}>
            <i className="ti ti-chevron-left" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={handleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item nav-welcome">
            <h6>
              Hello! {capitalizeFirstLetter(name)}
              {/* {name}  */}
              <img src={hello} className="mx-1" alt="" />
              Welcome Back!
            </h6>
          </li>
          {/* /Search */}
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  <img src={profile_img} className="img-fluid" alt="Profile" />
                </span>
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link to={findPath === 'super-admin' ? routes.adminProfile.path : routes.profile.path} className="dropdown-item">
                <i className="ti ti-user-check" /> My Profile
              </Link>
              <Link
                to={"#"}
                className="dropdown-item"
                onClick={() => {
                  handleLogout(findPath);
                }}
              >
                <i className="ti ti-logout" /> Logout
              </Link>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
      </div>
      {/* /Header */}
    </>
  );
};

export default Header;
