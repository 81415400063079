import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { Chips } from "primereact/chips";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import {
  onlyNumber,
  DESCRIPTION_LENGTH,
  nameMaxLength,
  pricePerMonth,
  pricePerYear,
  planNumber,
} from "../../../../utils/patterns/regex.pattern";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { clearEditData } from "../../../../core/redux/commonSlice";

const AddEditPricingPlan = ({ successcall = () => {} }) => {
  const { postData, putData } = useContext(ApiServiceContext);

  const data = useSelector((state: any) => state.common.editData);
  const plan_id = data?.plan_id;
  const planType = [
    { name: "Flat", code: 1 },
    { name: "Percentage", code: 2 },
  ];
  const planValue = [
    { name: "Monthly", code: 1 },
    { name: "Yearly", code: 2 },
  ];

  const validationSchema = Yup.object().shape({
    plan_name: Yup.string().trim().required("Plan name is required"),
    amount_month: Yup.string().trim().required("Price per month is required"),
    amount_year: Yup.string().trim().required("Price per year is required"),
    plan_description: Yup.string()
      .trim()
      .required("Plan description is required"),
    status: Yup.boolean(),
    discount_amount: Yup.string(),
    planFeatures: Yup.array().min(1, "Plan features is required"),
    planFeatures_not: Yup.array().min(1, "Not plan features is required"),
    discount_type: Yup.object({
      name: Yup.string().trim(),
    }).nullable(),
    discount_for: Yup.object({
      name: Yup.string().trim(),
    }).nullable(),
    fleet_manager: Yup.string().required("Number of fleet manager is required"),

    service_manager: Yup.string().required(
      "Number of service manager is required"
    ),

    drivers: Yup.string().required("Number of driver is required"),

    vehicle: Yup.string().required("Number of vehicle is required"),
  });
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      plan_name: "",
      amount_month: "",
      amount_year: "",
      plan_description: "",
      status: true,
      discount_amount: "",
      planFeatures: [],
      planFeatures_not: [],
    },
  });
  const [features, setFeatures] = useState([]);
  const [notfeatures, setNotFeatures] = useState([]);

  const dispatch = useDispatch();

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    setFeatures([]);
    setNotFeatures([]);
  };

  useEffect(() => {
    if (plan_id) {
      const defaultDiscountType =
        planType.find((option) => option.code === data?.discount_type) || null;
      const defaultDiscountFor =
        planValue.find((option) => option.code === data?.discount_for) || null;
      setValue("plan_name", data.plan_name || "");
      setValue("amount_month", data.amount_month || "");
      setValue("amount_year", data.amount_year || "");
      setValue("plan_description", data.plan_description || "");
      setValue("status", data.status === 1 ? true : false);
      setValue("discount_amount", data.discount_amount || "");
      setValue("planFeatures", data.available_PlanFeatures || []);
      setValue("planFeatures_not", data.notAvailable_PlanFeatures || []);
      setValue("discount_type", defaultDiscountType || "");
      setValue("discount_for", defaultDiscountFor || "");
      setFeatures(data.available_PlanFeatures || []);
      setNotFeatures(data.notAvailable_PlanFeatures || []);
      setValue("drivers", data.drivers || "");
      setValue("fleet_manager", data.fleet_manager || "");
      setValue("service_manager", data.service_manager || "");
      setValue("vehicle", data.vehicle || "");
    }
  }, [data, setValue]);

  const onSubmit = async (data: any) => {
    data.currency = "$";
    data.status = data.status === true ? 1 : 0;

    if (plan_id) {
      data.id = plan_id;
      // data.discount_for = data.discount_for.code;
      data.currency = "$";
    }

    data.discount_type = data?.discount_type?.code;
    data.discount_for = data?.discount_for?.code;
    try {
      let urls = plan_id
        ? end_points.update_priceplan.url
        : end_points.create_priceplan.url;

      const response = plan_id
        ? await putData(urls, data)
        : await postData(urls, data);
      dispatch(setAddCanvasSidebar(!offcanvas));

      if (response.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        setFeatures([]);
        setNotFeatures([]);
        successcall();
        dispatch(clearEditData());
      }
    } catch (e) {
      //console.log(e);
    }
  };

  

  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">{plan_id ? "Edit" : "Add"} Plan</h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark" />
            </Link>
          </div>
          <div className="card-body">
            <div className="truck-information">
              <h4 className="mb-1">Pricing Plan</h4>
              <p className="text-muted">
                Please add or edit details to save pricing plan
              </p>
              <div className="truck-information-grid mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Plan Name <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="plan_name"
                        control={control}
                        render={({ field }) => (
                          <input
                            maxLength={nameMaxLength}
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Name"
                            {...field}
                            onBlur={() => trigger("plan_name")}
                            onKeyDown={(e) => {
                              if (
                                !/[a-zA-Z0-9\s]/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("plan_name");
                            }}
                          />
                        )}
                      />
                      {errors.plan_name && (
                        <small className="text-danger">
                          {errors.plan_name.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        Price Per Month <span className="manitary">*</span>
                      </label>
                      <div className="input-append pricing">
                        <Controller
                          name="amount_month"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              maxLength={pricePerMonth}
                              onBlur={() => trigger("amount_month")}
                              placeholder="Enter Amount"
                              className="form-control"
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger("amount_month");
                              }}
                              onKeyDown={(event) => {
                                const regex = onlyNumber;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        <span>
                          <i className="ti ti-currency-dollar" />
                        </span>
                      </div>
                      {errors.amount_month && (
                        <small className="text-danger">
                          {errors.amount_month.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        Price Per Year <span className="manitary">*</span>
                      </label>
                      <div className="input-append pricing">
                        <Controller
                          name="amount_year"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              maxLength={pricePerYear}
                              type="text"
                              onBlur={() => trigger("amount_year")}
                              placeholder="Enter Amount"
                              className="form-control"
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger("amount_year");
                              }}
                              onKeyDown={(event) => {
                                const regex = onlyNumber;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        <span>
                          <i className="ti ti-currency-dollar" />
                        </span>
                      </div>
                      {errors.amount_year && (
                        <small className="text-danger">
                          {errors.amount_year.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>Discount Type</label>
                      <Controller
                        name="discount_type"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            filter
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={planType}
                            optionLabel="name"
                            placeholder="Select"
                            className="w-100"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>Discount For</label>
                      <Controller
                        name="discount_for"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            filter
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={planValue}
                            optionLabel="name"
                            placeholder="Select"
                            className="w-100"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>Discount Amount </label>
                      <div className="input-append pricing">
                        <Controller
                          name="discount_amount"
                          control={control}
                          render={({ field }) => (
                            <input
                              maxLength={pricePerMonth}
                              {...field}
                              type="text"
                              placeholder="Enter Amount"
                              className="form-control"
                              onKeyDown={(event) => {
                                const regex = onlyNumber;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger("discount_amount");
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        No of fleet manager <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="fleet_manager"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Number"
                            {...field}
                            maxLength={planNumber}
                            onChange={(event: any) => {
                              field.onChange(event);
                              trigger("fleet_manager");
                            }}
                            onBlur={() => trigger("fleet_manager")}
                            onKeyDown={(event) => {
                              const regex = onlyNumber;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                      {errors.fleet_manager && (
                        <small className="text-danger">
                          {errors.fleet_manager.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        No of service manager{" "}
                        <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="service_manager"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Number"
                            {...field}
                            maxLength={planNumber}
                            onChange={(event: any) => {
                              field.onChange(event);
                              trigger("service_manager");
                            }}
                            onBlur={() => trigger("service_manager")}
                            onKeyDown={(event) => {
                              const regex = onlyNumber;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                      {errors.service_manager && (
                        <small className="text-danger">
                          {errors.service_manager.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        No of driver <span className="manitary">*</span>
                      </label>
                      <div className="input-append">
                        <Controller
                          name="drivers"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Number"
                              {...field}
                              maxLength={planNumber}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger("drivers");
                              }}
                              onBlur={() => trigger("drivers")}
                              onKeyDown={(event) => {
                                const regex = onlyNumber;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        {errors.drivers && (
                          <small className="text-danger">
                            {errors.drivers.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        No of vehicle <span className="manitary">*</span>
                      </label>
                      <div className="input-append">
                        <Controller
                          name="vehicle"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Number"
                              {...field}
                              maxLength={planNumber}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger("vehicle");
                              }}
                              onBlur={() => trigger("vehicle")}
                              onKeyDown={(event) => {
                                const regex = onlyNumber;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        {errors.vehicle && (
                          <small className="text-danger">
                            {errors.vehicle.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Features <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="planFeatures"
                        control={control}
                        render={({ field }) => (
                          <Chips
                            placeholder="Enter Features & Hit Enter"
                            {...field}
                            value={features}
                            onChange={(event: any) => {
                              const value = event.value;
                              setFeatures(value);
                              setValue("planFeatures", value);
                              field.onChange(event);
                              trigger("planFeatures");
                            }}
                            onKeyDown={(e: any) => {
                              const currentInput = e.target.value;
                              if (
                                currentInput.length >= 30 &&
                                e.key.length === 1 && // Check if the pressed key is a character
                                e.key !== "Backspace" &&
                                e.key !== "Delete"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                      {errors.planFeatures && (
                        <small className="text-danger">
                          {errors.planFeatures.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Not Plan Features <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="planFeatures_not"
                        control={control}
                        render={({ field }) => (
                          <Chips
                            placeholder="Enter Not Plan Features & Hit Enter"
                            {...field}
                            value={notfeatures}
                            onChange={(event: any) => {
                              const value = event.value;
                              setNotFeatures(value);
                              setValue("planFeatures_not", value);
                              field.onChange(event);
                              trigger("planFeatures_not");
                            }}
                            onKeyDown={(e: any) => {
                              const currentInput = e.target.value;
                              if (
                                currentInput.length >= 30 &&
                                e.key.length === 1 && // Check if the pressed key is a character
                                e.key !== "Backspace" &&
                                e.key !== "Delete"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        )}
                      />
                      {errors.planFeatures_not && (
                        <small className="text-danger">
                          {errors.planFeatures_not.message}
                        </small>
                      )}
                    </div>
                    {/* custom */}
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Plan Description <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="plan_description"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            className="form-control"
                            placeholder="Enter Plan Description"
                            {...field}
                            maxLength={DESCRIPTION_LENGTH}
                            onBlur={() => trigger("plan_description")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("plan_description");
                            }}
                          />
                        )}
                      />
                      {errors.plan_description && (
                        <small className="text-danger">
                          {errors.plan_description.message}
                        </small>
                      )}
                    </div>
                    <div className="mb-3">
                      <p className="text-muted">
                        <i className="ti ti-info-circle me-2" />
                        Maximum 255 Characters
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex align-items-center justify-content-between status-div">
                <h6>Status</h6>
                <div className="toggles">
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        <div className="slider round">
                          <span className="on">
                            <i className="ti ti-check" />
                          </span>
                          <span className="off">
                            <i className="ti ti-x" />
                          </span>
                        </div>
                      </label>
                    )}
                  />
                  {errors.status && (
                    <small className="text-danger">
                      {errors.status.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer canvas-btn tutorial-footer">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
            <Link
              to="#"
              className="btn btn-add"
              onClick={handleSubmit(onSubmit)}
            >
              {plan_id ? "Update" : "Save"}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditPricingPlan;
