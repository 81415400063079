import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginLogo } from "../../utils/imagepath";
import OtpInput from "react-otp-input";
import { ApiServiceContext } from "../../utils/shared.module";
import { end_points } from "../../services/end_point/end_points";
import { SpinnerContext } from "../../components/spinner/spinner";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoginToken,
  setUserDetails,
  setRole,
} from "../../core/redux/loginSlice";
import { routes } from "../../utils/routes";
import { useLocation } from "react-router-dom";

const Otp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { postData } = useContext(ApiServiceContext);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [validError, setValidError] = useState(false);

  const userDetails = useSelector((state: any) => state.login.userDetails);

  const location = useLocation();
  const subdomain = useSelector((state: any) => state.login.domain);

  const { otp_email, url } = location.state || {};
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (otp === "") {
      setValidError(false);
      setErrorMessage(true);
      return false;
    } else if (otp.length < 6) {
      setErrorMessage(false);
      setValidError(true);
    } else {
      try {
        setValidError(false);
        setErrorMessage(false);
        let urls;
        if(url){
          if (isAdmin === 'super-admin') {
            urls = end_points.otp_super_admin.url; //super-admin
          } else {
            urls = end_points.otp_admin.url; //admin
          }
        }else{
          if (isAdmin === 'super-admin') {
            urls = end_points.otp_verify.url; //super-admin
          } else {
            urls = end_points.admin_otp_verify.url; //admin
          }
        }
        

        const payload = {
          otp,
          email: otp_email ? otp_email : userDetails.email,
        };
        const response = await postData(urls, payload);
        if (response?.status === 200) {
          if (otp_email) {
            if (isAdmin === 'super-admin') {
              navigate(routes.resetPassword.path);
            } else {
              navigate(routes.adminResetPassword.path);
            }
          }
          const loginData = response?.data?.data?.user;
          const token = response?.data?.data?.token;
          const role = response?.data?.data?.user?.roleid;
          if (token) {
            dispatch(setLoginToken(token));
            dispatch(setUserDetails(loginData));
            dispatch(setUserDetails(loginData));
            dispatch(setRole(role));

            if (role === 1) {
              navigate(routes.adminDashboard.path);
            } else {
              navigate(routes.dashboard.path);
            }
          }
        }
      } catch (error: any) {}
    }
  };

  return (
    <>
      {" "}
      <div className="forgot-page-wrapper">
        <div className="forgot-page-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="static-page">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
                      <div className="forgot-pwd-topper">
                        <div className="forgot-pwd text-center">
                          <img src={loginLogo} alt="img" />
                          <div className="forgot-password-inner">
                            <div className="forgot-password-title text-center">
                              <h5>Verify Your Account</h5>
                              <p>
                                Enter the 6 digit code sent to the registered
                                email id
                              </p>
                            </div>
                            <div className="forgot-password-form otp-form mt-4">
                              <h6>6 Digit Code</h6>
                              <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="row row-digit-code">
                                  <div className="col-2 col-md-2">
                                    <OtpInput
                                      value={otp}
                                      onChange={(value) => {
                                        const numericValue = value.replace(
                                          /\D/g,
                                          ""
                                        );
                                        setOtp(numericValue);
                                      }}
                                      numInputs={6}
                                      inputType={"text"}
                                      inputStyle="twofa_otpinput"
                                      renderInput={(props) => (
                                        <input {...props} />
                                      )}
                                    />
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn submit-btn btn-hvr w-100 mt-4"
                                >
                                  Verify
                                </button>
                                {errorMessage && (
                                  <div
                                    className="alert alert-danger text-center custom-otp-warning"
                                    role="alert"
                                  >
                                    {"OTP is Required"}
                                  </div>
                                )}
                                {validError && (
                                  <div
                                    className="alert alert-danger text-center custom-otp-warning"
                                    role="alert"
                                  >
                                    {"Enter valid OTP"}
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
