import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  miniSidebar: false,
  mobileSidebar: false,
  expandMenu: false,
  addCanvasSidebar: false,
  editCanvasSidebar: false,

};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setMiniSidebar: (state, action) => {
      state.miniSidebar = action.payload;
    },
    setMobileSidebar: (state, action) => {
      state.mobileSidebar = action.payload;
    },
    setExpandMenu: (state, { payload }) => {
      state.expandMenu = payload;
    },
    resetMobileSidebar: (state) => {
      state.mobileSidebar = false;
    },
    setAddCanvasSidebar: (state, { payload }) => {
      state.addCanvasSidebar = payload;
    },
    setEditCanvasSidebar: (state, { payload }) => {
      state.editCanvasSidebar = payload;
    },
  },
});

export const {
  setMiniSidebar,
  setMobileSidebar,
  setExpandMenu,
  resetMobileSidebar,
  setAddCanvasSidebar,
  setEditCanvasSidebar
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
