import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import "yet-another-react-lightbox/styles.css";
import { useDispatch, useSelector } from "react-redux";
import AddService from "./add-service";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import SubItem from "./subItem";

const InspectionPreview = () => {
  const inspectionData = useSelector((state: any) => state.common.inspectionData);

  const Insp_id = inspectionData?.insp_Id;

  const [openItem, setOpenItem] = useState<number | null>(null);
  const [inspectionListData, setInspectionListData] = useState<any>({});

  const toggleCollapse = (index: number) => {
    setOpenItem(openItem === index ? null : index);
  };
  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const { getData } = useContext(ApiServiceContext);
  const [managerListData, setManagerListData] = useState<any[]>([]);

 
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const getList = async () => {
    try {
      let url = end_points.get_allservicemanager.url;

      const response = await getData(url);
      if (response.status === 200) {
        const serviceManagerList = response?.data?.data?.user?.map(
          (manager: any) => {
            return { name: manager?.first_name, code: manager?.user_id };
          }
        );
        setManagerListData(serviceManagerList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getInspectionList = async (id: any) => {
    try {
      let url = end_points.getInpectionDetailsApi.url;
      if (id) {
        url += `?Insp_id=${id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const inspectionList = response?.data?.data?.trip?.ck_json
          ?.replace(/\n/g, "")
          ?.replace(/\\/g, "");
        const data = inspectionList && JSON.parse(inspectionList);
        setInspectionListData(data);
        console.log("Logggg",data)
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(inspectionListData, "inspectionListData");

  useEffect(() => {
    getList();
    Insp_id && getInspectionList(Insp_id);
  }, [Insp_id]);

  const ChecklistDisplay = ({ data }: { data: any }) => {
    const inspectionCounts = Array.isArray(data?.inspectionCounts)
      ? data?.inspectionCounts
      : [];

    const checklistClasses: any = [];

    inspectionCounts?.forEach((inspection: any) => {
      const { majorCount, minorCount, checklist_completed } = inspection;

      let checkListClass = "check-list-grey";

      if (checklist_completed) {
        if (majorCount > 0) {
          checkListClass = "check-list-danger";
        } else if (minorCount > 0) {
          checkListClass = "check-list-warning";
        } else {
          checkListClass = "check-list-active";
        }
      }

      checklistClasses.push(checkListClass);
    });

    const completedCount = inspectionCounts?.filter(
      (inspection: any) => inspection?.checklist_completed
    ).length;

    return (
      <li>
        <p>Checklist</p>
        <div className="data-check-list">
          <ul className="nav">
            {checklistClasses.map((checkListClass: any, index: any) => (
              <li key={index}>
                <span className={checkListClass} />
              </li>
            ))}
            <li>
              <b>({completedCount}/6)</b>
            </li>
          </ul>
        </div>
      </li>
    );
  };

  const itemStatus = (item: any) => {
    return (
      <>
        <div className="d-flex">
          {item?.MajorCount > 0 ? (
            <div className="faq-badge">
              <span className="vehicle-badge bg-require">
                {item?.MajorCount || 0} Major issue
              </span>
            </div>
          ) : null}
          {item?.MinorCount > 0 && item?.MajorCount == 0 ? (
            <div className="faq-badge">
              <span className="vehicle-badge status-inprogress">
                {item?.MinorCount || 0} Minor issue
              </span>
            </div>
          ) : null}
          {item?.MinorCount == 0 && item?.MajorCount == 0 ? (
            <div className="faq-badge">
              <span className="vehicle-badge bg-active">No issue</span>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const hasMajorIssue = (subitems: any) => {
    return subitems.some((subitem: any) => subitem.answer == 2);
  };

  const hasRepairRequired = (inspections:any) => {
    return inspections?.some((inspection:any) =>
      inspection?.items?.some((item:any) =>
        item?.subitem?.some((subItem:any) => subItem.answer == 2)
      )
    );
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Inspection ID - {inspectionData?.inspection_refid}</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.inspectionList.path}>
                      Inspection Tracking
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{inspectionData?.inspection_refid}</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Inspection Tracking */}
          <div className="inspection-info">
            {/* Inspection Details */}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="vehicle-grid inspection-grid">
                      <div className="vehicle-grid-img">
                        <img src={inspectionData?.vehicle?.vehicle_image} className="img-fluid" alt="Truck" />
                      </div>
                      <div className="vehicle-grid-content">
                        <div className="vehicle-content-top">
                          <div className="vehicle-id-info">
                            <ul className="nav vehicle-id-list">
                              <li>
                                <h4>{inspectionData?.vehicle?.vehicle_name}</h4>
                              </li>
                              {hasRepairRequired(inspectionListData?.inspection) && (
                              <li>
                                <span className="vehicle-badge bg-require">
                                  Repair Required
                                </span>
                              </li>
                              )}
                            </ul>
                            {/* {hasRepairRequired(inspectionListData?.inspection) && (
                            <ul className="nav vehicle-id-edit">
                              <li>
                                <Link
                                  to="#"
                                  className="btn btn-add"
                                  onClick={handleOffcanvas}
                                >
                                  <i className="ti ti-circle-plus" /> Assign to
                                  Service
                                </Link>
                              </li>
                            </ul>
                            )} */}
                          </div>
                          <div className="vehicle-make-list">
                            <ul className="nav">
                              <li>{inspectionData?.vehicle?.make}</li>
                              <li>{inspectionData?.vehicle?.vehical_typename}</li>
                              <li>{inspectionData?.vehicle?.vehical_number}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="vehicle-content-bottom">
                          <ul className="nav">
                            <li>
                              <p>Inspection Details</p>
                              <h6>{inspectionData?.driver}</h6>
                            </li>
                            <li>
                              <p>Inspection Date</p>
                              <h6>{formatDate(inspectionData?.inspection_Date)}</h6>
                            </li>
                            <li>
                              <p>Comments</p>
                              <h6>{inspectionData?.comments}</h6>
                            </li>
                            <ChecklistDisplay data={inspectionData} />
                            <li>
                              <p>Driver Signature</p>
                              <h6>
                                <img
                                  src={inspectionData?.driver_signatureImage}
                                  alt="Signature"
                                />
                              </h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="inspection-grid-list">
                      <ul
                        className="nav nav-tabs"
                        id="inspectionTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <Link
                            to="#checklist_overview"
                            className="nav-link active"
                            id="checklist-overview"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="checklist_overview"
                            aria-selected="true"
                          >
                            Checklist Overview
                          </Link>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                          <Link to="#" className="nav-link">
                            Trip Assigned Details
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Inspection Details */}
            {/* Inspection Tab */}
            <div className="row">
              <div className="col-md-12">
                <div className="tab-content" id="inspectionTabContent">
                  {/* Checklist */}
                  <div
                    className="tab-pane fade show active"
                    id="checklist_overview"
                    role="tabpanel"
                    aria-labelledby="checklist-overview"
                  >
                    <div className="row" id="checklist">
                      <div className="col-lg-12">
                        {/* Checklist Info */}
                        {inspectionListData?.inspection?.map(
                          (item: any, index: any) => {
                            return (
                              <>
                                <div className="card faq-card">
                                  <div className="card-body">
                                    <div className="faq-header">
                                      <Link
                                        className="faq-collapse"
                                        to={`#inspection-issues_${index + 1}`}
                                        data-bs-toggle="collapse"
                                        aria-expanded={openItem === index}
                                        onClick={() => toggleCollapse(index)}
                                      >
                                        <div className="faq-title">
                                        <h6>
                                          {item?.ck_title}
                                          {item?.checklist_completed && (
                                          <span
                                            className={`faq-icon ${
                                              item?.items.some((subItem: any) =>
                                                hasMajorIssue(subItem.subitem)
                                              ) ? (
                                                "faq-icon-danger"
                                              ) : (
                                                "faq-icon-complete"
                                              )
                                            }`}
                                          >
                                            {item?.items.some((subItem: any) =>
                                              hasMajorIssue(subItem.subitem)
                                            ) ? (
                                              <i className="ti ti-alert-triangle-filled" />
                                            ) : (
                                              <i className="ti ti-check" />
                                            )}
                                          </span>
                                          )}
                                        </h6>
                                          <p>{item?.ck_description}</p>
                                        </div>
                                        {itemStatus(item)}
                                      </Link>
                                    </div>
                                    <div
                                      className={`collapse ${
                                        openItem === index ? "show" : ""
                                      }`}
                                      id={`inspection-issues_${index + 1}`}
                                      data-bs-parent="#checklist"
                                    >
                                      <div className="faq-details">
                                        <div className="checklist-description">
                                          {item?.items?.map(
                                            (subMenuItem: any, index: any) => {
                                              return (
                                                <SubItem
                                                  subItem={subMenuItem}
                                                  index={index}
                                                />
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <AddService managerListData={managerListData} />}
      {/* <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Body>
          <GalleryList
            galleryItems={galleryItems}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default InspectionPreview;
